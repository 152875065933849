import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AddressForm from '~/components/AddressForm';
import PageHeader from '~/components/Common/PageHeader';
import InputSelect from '~/components/Inputs/InputSelect';
import Loading from '~/components/Loading';
import { deliveryFleets } from '~/util/deliveryFleets';

import api from '~/services/api';
import MobileHeader from '~/components/HorizontalLayout/Header/MobileHeader';
import { updateDelivery } from '~/store/modules/cart/actions';

const ColectDeliveryForm = ({
  setShouldShowColectDeliveryForm,
  sale,
  role,
  client,
  setShouldReloadCart,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [deliveryTag, setDeliveryTag] = useState(null);

  const vehiclesAvailable = [
    { value: 'M', label: 'Moto' },
    ...(deliveryTag && deliveryTag.tag === 'UBER'
      ? [{ value: 'C', label: 'Carro' }]
      : []),
  ];

  const [loading, setLoading] = useState(false);
  const [iDontKnowCEP, setIDontKnowCEP] = useState(false);
  const [deliveryServices, setDeliveryServices] = useState([]);

  const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(false);

  const [storesAvailable, setStoresAvailable] = useState([]);

  const [vehicleType, setVehicleType] = useState(
    vehiclesAvailable.find(vehicle => vehicle.value === sale.delivery_vehicle)
  );

  const [selectedStore, setSelectedStore] = useState(null);

  const userStores = useSelector(({ user }) =>
    user.profile.managed_stores.map(s => ({
      value: s.id,
      label: s.fantasy_name,
      has_mapp: s.has_mapp,
    }))
  );

  useEffect(() => {
    const getDeliveryServices = async () => {
      try {
        const {
          data: { delivery_services },
        } = await api.get(`/utils/available-delivery-services`);

        const getDeliveryServicesAvailable = deliveryFleets.filter(
          type => delivery_services.includes(type.tag) || type.tag === 'RETIRAR'
        );

        const getDeliveryServicesAvailableWithSubscriber =
          getDeliveryServicesAvailable.map(type =>
            type.tag === 'PROPRIA'
              ? {
                  ...type,
                  label: `Alternativa`,
                  value: type.tag,
                  is_for_delivery: type.value,
                }
              : { ...type, value: type.tag, is_for_delivery: type.value }
          );

        const _deliveryTag = getDeliveryServicesAvailableWithSubscriber.find(
          service => service.value === sale.delivery_method
        );

        setDeliveryTag(_deliveryTag);

        let availableStores = [];
        if (
          _deliveryTag?.tag === 'BEE' ||
          _deliveryTag?.tag === 'MOTTU' ||
          _deliveryTag?.tag === 'UBER' ||
          _deliveryTag?.tag === 'CORREIOS' ||
          _deliveryTag?.tag === 'LALAMOVE' ||
          _deliveryTag?.tag === 'RETIRAR'
        ) {
          availableStores = userStores;
        }

        if (_deliveryTag?.tag === 'PROPRIA') {
          availableStores = userStores.filter(s => s.has_mapp);
        }
        setStoresAvailable(_ => availableStores);

        setSelectedStore(
          availableStores.find(store => store.value === sale.store.id)
        );
        setLoading(false);
      } catch (err) {
        console.log(err);
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Erro ao carregar os serviços de entrega!');
        }
        setLoading(false);
      }
    };

    getDeliveryServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getDeliveryServices = async () => {
      try {
        const {
          data: { delivery_services },
        } = await api.get(`/utils/available-delivery-services`);

        const getDeliveryServicesAvailable = deliveryFleets.filter(
          type => delivery_services.includes(type.tag) || type.tag === 'RETIRAR'
        );

        const getDeliveryServicesAvailableWithSubscriber =
          getDeliveryServicesAvailable.map(type =>
            type.tag === 'PROPRIA'
              ? {
                  ...type,
                  label: `Alternativa`,
                  value: type.tag,
                  is_for_delivery: type.value,
                }
              : { ...type, value: type.tag, is_for_delivery: type.value }
          );

        setDeliveryServices(getDeliveryServicesAvailableWithSubscriber);
        setDeliveryTag(
          getDeliveryServicesAvailableWithSubscriber.find(
            service => service.value === sale?.delivery_method
          )
        );
        setLoading(false);
      } catch (err) {
        console.log(err);
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Erro ao carregar os serviços de entrega!');
        }
        setLoading(false);
      }
    };

    getDeliveryServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitAddress = async payload => {
    try {
      setLoading(true);

      await api.patch(`${role}/carts/${sale.id}`, {
        return_delivery_address: payload,
        client_id: sale.client.id,
        delivery_method: sale.delivery_method,
        return_delivery_method: deliveryTag?.tag,
        return_delivery_vehicle: vehicleType?.value,
        is_for_delivery: true,
        return_store_id: selectedStore.value,
      });

      await api.patch(`${role}/carts/${sale.id}/return-delivery`);

      setShouldReloadCart(true);
      setShouldShowColectDeliveryForm(false);
    } catch (e) {
      console.error(e);
    }
  };

  const submitHandler = async () => {
    try {
      formRef.current?.submitForm();
    } catch (e) {
      console.error(e);
    }
  };

  const storeSelectorStyles = {
    menu: base => ({
      ...base,
      maxHeight: 250,
      width: '100%',
    }),
    menuList: base => ({
      ...base,
      maxHeight: 250,
      width: '100%',
    }),
  };

  if (loading)
    return (
      <>
        <MobileHeader
          saleDetails
          arrowAction={() => setShouldShowColectDeliveryForm(false)}
          pageTitle="Informações da coleta"
        />
        <div className="page-content">
          <Container className="container new-layout wide">
            <Row className="w-100 mx-0 d-none d-lg-flex">
              <Col xs={10} className="px-0">
                <PageHeader
                  responsivePosition="start"
                  desktopPosition="start"
                  backTo={() => setShouldShowColectDeliveryForm(false)}
                />
              </Col>
            </Row>
            <Card className="new-layout card">
              <CardBody className="new-layout card-body px-4 py-3">
                <Row className="font-size-20 font-weight-600 px-4 mt-2 mb-2">
                  Informações para coleta
                </Row>
                <Row className="px-4 mb-3 mx-auto">
                  <Loading
                    customClassName="loadingpix mx-auto"
                    message="Consultando os serviços de entrega!"
                  />
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </>
    );

  return (
    <>
      <MobileHeader
        saleDetails
        arrowAction={() => setShouldShowColectDeliveryForm(false)}
        pageTitle="Informações da coleta"
      />
      <div className="page-content">
        <Container className="container new-layout wide">
          <Row className="w-100 mx-0 d-none d-lg-flex">
            <Col xs={10} className="px-0">
              <PageHeader
                responsivePosition="start"
                desktopPosition="start"
                backTo={() => setShouldShowColectDeliveryForm(false)}
              />
            </Col>
          </Row>

          <Card className="new-layout card">
            <CardBody className="new-layout card-body px-4 py-3">
              <Row className="font-size-20 font-weight-600 px-4 mt-2 mb-2">
                Informações para{' '}
                {sale.is_suitcase_delivery ? 'coleta' : 'devolução'}
              </Row>
              <Row className="px-4 mb-3">
                <AddressForm
                  initialData={client?.address}
                  formRef={formRef}
                  handleSubmit={handleSubmitAddress}
                  submitBtnDisabled={btnSubmitDisabled}
                  setSubmitBtnDisabled={setBtnSubmitDisabled}
                  sn={false}
                  iDontKnowCEP={iDontKnowCEP}
                  setIDontKnowCEP={setIDontKnowCEP}
                />
              </Row>
            </CardBody>
          </Card>

          <Card className="new-layout card">
            <CardBody className="new-layout card-body px-4 py-2">
              <Row className="px-4 mt-2 mb-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <Label className="m-0">Transportadora</Label>
                  <Form>
                    <Row className="w-100 mx-0 mt-2 mb-0">
                      <FormGroup className="w-100">
                        <InputSelect
                          name="delivery_service"
                          options={deliveryServices}
                          onChange={e => {
                            dispatch(
                              updateDelivery({
                                tag: e.tag,
                                value: e.is_for_delivery,
                              })
                            );
                            setDeliveryTag({
                              tag: e.tag,
                              value: e.is_for_delivery,
                            });
                          }}
                          value={deliveryServices.find(
                            deliveryService =>
                              deliveryService.tag === deliveryTag?.tag
                          )}
                          placeholder="Selecione a Transportadora"
                          isSearchable
                          type="text"
                          styles={storeSelectorStyles}
                        />
                      </FormGroup>
                    </Row>
                  </Form>
                </Col>
                {(deliveryTag?.tag === 'BEE' ||
                  deliveryTag?.tag === 'UBER') && (
                  <Col lg={6} className="px-0 pl-lg-2">
                    <Label className="m-0">Tipo de veículo</Label>
                    <Form>
                      <Row className="w-100 mx-0 mt-2 mb-0">
                        <FormGroup className="w-100">
                          <InputSelect
                            name="vehicle"
                            options={vehiclesAvailable}
                            onChange={e => setVehicleType(e)}
                            value={vehicleType}
                            placeholder="Tipo de veículo"
                            isSearchable
                            type="text"
                            loadingMessage={() => 'Buscando veículos...'}
                            styles={storeSelectorStyles}
                          />
                        </FormGroup>
                      </Row>
                    </Form>
                  </Col>
                )}
                <Col
                  lg={6}
                  className={`px-0 pr-lg-2 ${
                    deliveryTag?.tag !== 'BEE' && deliveryTag?.tag !== 'UBER'
                      ? 'pl-lg-2'
                      : ''
                  }`}
                >
                  <Label className="m-0">Loja para entrega</Label>
                  <Form>
                    <Row className="w-100 mx-0 mt-2 mb-0">
                      <FormGroup className="w-100">
                        <InputSelect
                          options={storesAvailable}
                          value={selectedStore}
                          onChange={e => setSelectedStore(e)}
                          name="store_id"
                          isSearchable
                          placeholder="Nome da loja"
                          type="text"
                          loadingMessage={() => 'Buscando lojas...'}
                          styles={storeSelectorStyles}
                        />
                      </FormGroup>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row className="w-100 mx-0 mb-4">
            <Col className="px-0 w-100 d-flex justify-content-end">
              <Button
                color="primary"
                size="lg"
                type="submit"
                className="w-100 new-layout-btn main w-lg-25"
                disabled={btnSubmitDisabled}
                onClick={submitHandler}
              >
                Avançar
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ColectDeliveryForm;
