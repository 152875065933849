import * as Yup from 'yup';

const schema = (
  maskTelephoneLength,
  maskFinancierTelephoneLength,
  maskJustaOwnerCellphoneLength
) =>
  Yup.object().shape({
    name: Yup.string().required('Insira a razão social da loja'),
    fantasy_name: Yup.string().required('Insira o nome fantasia da loja'),
    responsible_name: Yup.string().required(
      'Insira o nome do responsável da loja'
    ),
    email: Yup.string()
      .email('E-mail no formato inválido')
      .required('Insira o e-mail do responsável da loja'),
    cnpj: Yup.string()
      .min(18, 'Insira todos os dígitos do CNPJ')
      .required('Digite o CNPJ da loja'),
    telephone: Yup.string()
      .min(maskTelephoneLength, 'Insira todos os dígitos do número do telefone')
      .required('Insira o telefone da loja'),
    address: Yup.object().shape({
      cep: Yup.string()
        .required('Insira o CEP corretamente')
        .min(9, 'Insira todos os dígitos do CEP'),
      street_name: Yup.string().required('Insira o nome da rua'),
      number: Yup.string().required('Insira o número'),
      neighborhood: Yup.string().required('Insira o nome do bairro'),
      state: Yup.string().required('Insira o nome do estado'),
      city: Yup.string().required('Insira o nome da cidade'),
      complement: Yup.string()
        .notRequired()
        .max(128, 'Máximo de 128 caracteres'),
      additional_information: Yup.string()
        .notRequired()
        .max(
          128,
          'As informações adicionais devem ter no máximo 128 caracteres'
        ),
    }),
    financier_email: Yup.string().email('E-mail no formato inválido'),
    financier_telephone: Yup.string().min(
      maskFinancierTelephoneLength,
      'Insira todos os dígitos do número do telefone'
    ),
    store_group_id: Yup.string().notRequired(),
    customize: Yup.object().shape({
      theme_color: Yup.string().notRequired().nullable(),
      brand_logo: Yup.mixed().notRequired().nullable(),
    }),

    // Validações dinâmicas para Justa
    justa_cnae: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string().required('Insira o CNAE'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_constitution_date: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string().required('Insira a data de criação da loja'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_owner_name: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string().required('Insira o nome do responsável'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_owner_birth_date: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string().required('Insira a data de nascimento do responsável'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_owner_rg: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string()
        .required('Insira o RG')
        .max(9, 'Máximo de 9 caracteres'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_owner_cpf: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string()
        .required('Insira o CPF')
        .min(14, 'Insira todos os dígitos do CPF'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_owner_email: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string()
        .email('E-mail no formato inválido')
        .required('Insira o e-mail do responsável'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_owner_cellphone: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string()
        .required('Insira o celular do responsável')
        .min(maskJustaOwnerCellphoneLength, 'Insira todos os dígitos'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_account_bank_type: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string().required('Informe o tipo de conta'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_account_bank_code: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string()
        .required('Insira o código do banco')
        .max(3, 'Máximo de 3 caracteres'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_account_bank_agency: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string()
        .required('Insira a agência do banco')
        .min(4, 'Insira todos os dígitos da agência')
        .max(4, 'Máximo de 4 dígitos'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_account_bank_number: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string().required('Insira o número da conta'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_account_bank_cpf: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string()
        .required('Insira o CPF vinculado à conta')
        .min(14, 'Insira todos os dígitos do CPF'),
      otherwise: Yup.string().notRequired(),
    }),
    justa_account_bank_owner_name: Yup.string().when('$payment_gateway', {
      is: 'justa',
      then: Yup.string().required('Insira o favorecido da conta bancária'),
      otherwise: Yup.string().notRequired(),
    }),
  });

export default schema;
