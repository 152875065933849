/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  Row,
  Col,
  FormGroup,
  Button,
  Container,
  Card,
  CardBody,
  UncontrolledTooltip,
  Label,
  CustomInput,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { ValidationError } from 'yup';
import { toast, Flip } from 'react-toastify';
import IntlCurrencyInput from 'react-intl-currency-input';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import history from '~/services/history';
import { cpfSchema } from '~/validators/form/new_pix_link';
import { isDate, parse, isAfter } from 'date-fns';

import Input from '~/components/Inputs/Input';
import InputMask from '~/components/Inputs/InputMask';
import InputSelect from '~/components/Inputs/InputSelect';
// import InputSelectAsync from '~/components/Inputs/InputSelectAsync';
import SchemaValidator from '~/validators/form/add_store_form';
// import BankList from '~/Util/banklist';
import Loading from '~/components/Loading';
import PageHeader from '~/components/Common/PageHeader';
import IconButton from '~/components/IconButton';
import {
  bnplGatewayOptions,
  externalGatewayOptions,
  gatewayOptions,
} from '~/util/filters';
import { stateList } from '~/util/stateList';
import ImageInput from '~/components/Inputs/ImageInput';
import ColorPicker from '~/components/ColorPicker';
import { copyStringToClipboard } from '~/util/copyToClipboard';

const EditStore = () => {
  const formRef = useRef(null);
  const toastId = useRef(null);
  const certificateRef = useRef(null);
  const [cityList, setCityList] = useState([]);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [removeLogo, setRemoveLogo] = useState(false);
  const [pagarmeHub, setPagarmeHub] = useState(null);

  useEffect(() => {
    const getCities = async () => {
      const { data } = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState.value}/municipios`
      );

      const parsedCities = data.map(city => ({
        value: city.nome,
        label: city.nome,
      }));

      setCityList(parsedCities);
    };

    if (selectedState) getCities();
  }, [selectedState]);

  const [loading, setLoading] = useState(true);
  const [initialStore, setInitialStore] = useState();
  const [searchedCEP, setSearchedCEP] = useState('');
  const [onlinePaymentLimit, setOnlinePaymentLimit] = useState();
  const [minimumDeliveryBalance, setMinimumDeliveryBalance] = useState();
  const [defaultFreight, setDefaultFreight] = useState();
  const [installmentLimitAmount, setInstallmentLimitAmount] = useState();
  const [maximumDeliveryKilometer, setMaximumDeliveryKilometer] = useState();
  const [mottuUnitCode, setMottuUnitCode] = useState(null);

  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [justaAliasName, setJustaAliasName] = useState();
  const [justaCnae, setJustaCnae] = useState();
  const [justaConstitutionDate, setJustaConstitutionDate] = useState();
  const [justaOwnerName, setJustaOwnerName] = useState();
  const [justaOwnerCpf, setJustaOwnerCpf] = useState();
  const [justaOwnerEmail, setJustaOwnerEmail] = useState();
  const [justaOwnerCellphone, setJustaOwnerCellphone] = useState();
  const [justaOwnerPhone, setJustaOwnerPhone] = useState();
  const [justaOwnerBirthDate, setJustaOwnerBirthDate] = useState();
  const [justaOwnerRg, setJustaOwnerRg] = useState();
  const [justaAccountCpf, setJustaAccountCpf] = useState();
  const [justaAccountCode, setJustaAccountCode] = useState();
  const [justaAccountAgency, setJustaAccountAgency] = useState();
  const [justaAccountOwnerName, setJustaAccountOwnerName] = useState();
  const [justaAccountNumber, setJustaAccountNumber] = useState();

  const [color, setColor] = useState('#2544b1');
  const [logo, setLogo] = useState('');
  const [file, setFile] = useState('');

  const [selectedBnplGateway, setSelectedBnplGateway] = useState(
    bnplGatewayOptions[0]
  );
  const [selectedGateway, setSelectedGateway] = useState(gatewayOptions[0]);
  const mappLink = useSelector(
    ({ user }) => user?.profile?.subscriber?.mapp_url_webhook
  );

  const [cieloMerchantId, setCieloMerchantId] = useState('');
  const [cieloMerchantKey, setCieloMerchantKey] = useState('');

  const [telephoneV, setTelephone] = useState('');
  const [financierPhone, setFinancierPhone] = useState('');

  const { store_id } = useParams();

  // const [deliveryGroups, setDeliveryGroups] = useState([]);
  // const [selectedGroup, setSelectedGroup] = useState(null);

  const [certificate, setCertificate] = useState(null);
  const [certificateUrl, setCertificateUrl] = useState(null);

  const [noNumber, setNoNumber] = useState(false);
  const [externalGateway, setExternalGateway] = useState(false);
  const [splitPayment, setSplitPayment] = useState(false);
  const [clearsaleAppName, setClearsaleAppName] = useState(false);
  // this state stores fields to keep track after pagarme hub redirections
  const [extraPersistenceFields, setExtraPersistenceFields] = useState({});

  const accountTypes = useMemo(
    () => [
      {
        label: 'Poupança',
        value: 'saving',
      },
      {
        label: 'Corrente',
        value: 'checking',
      },
    ],
    []
  );

  const isConstitutionDateValid = value => {
    const parsedDate = isDate(value)
      ? value
      : parse(value, 'dd/MM/yyyy', new Date());

    if (isAfter(parsedDate, new Date())) {
      formRef.current.setFieldError(
        'justa_constitution_date',
        'Data de criação não pode ser no futuro'
      );
      return false;
    }

    return true;
  };

  const isOwnerBirthDateValid = value => {
    const parsedDate = isDate(value)
      ? value
      : parse(value, 'dd/MM/yyyy', new Date());

    if (isAfter(parsedDate, new Date())) {
      formRef.current.setFieldError(
        'justa_owner_birth_date',
        'Data de nascimento não pode ser no futuro'
      );
      return false;
    }

    return true;
  };

  const antifraudOptions = useMemo(
    () =>
      externalGateway
        ? [
            { label: 'Nenhum', value: 'nenhum' },
            { label: 'ClearSale', value: 'clearsale' },
          ]
        : [{ label: 'Nenhum', value: 'nenhum' }],
    [externalGateway]
  );

  const [selectedAntifraud, setSelectedAntifraud] = useState(
    antifraudOptions[0]
  );

  useEffect(() => {
    setSelectedAntifraud(antifraudOptions[0]);
  }, [antifraudOptions]);

  useEffect(() => {
    document.title = 'Editar Loja | Viddy';

    const loadStore = async () => {
      try {
        setLoading(true);
        const {
          data: { store },
        } = await api.get(`stores/${store_id}`);

        setInitialStore(store);
        setTelephone(store.telephone);
        setFinancierPhone(store.financier_telephone);
        setExternalGateway(
          Boolean(
            store.has_mercadopago_key ||
              store.has_rede_key ||
              store.has_pagseguro_key ||
              store.has_clearsale_key
          )
        );
        setCieloMerchantId(store.cielo_merchant_id);
        setCieloMerchantKey(store.cielo_merchant_key);
        setOnlinePaymentLimit(
          Number(store.online_payments_value_limit) || 1000
        );
        setColor(store?.customize?.theme_color);
        setLogo(store?.customize?.brand_logo?.url);

        if (store.has_clearsale_key) {
          setSelectedAntifraud({ label: 'Clear Sale', value: 'clearsale' });
        }

        if (store?.address?.number === 'sn') {
          setNoNumber(true);
        } else {
          formRef?.current?.setFieldValue(
            'address.number',
            store.address.number
          );
        }

        const state = stateList.find(s => s.value === store.address?.state);
        setSelectedState(state);

        const city = store.address?.city
          ? {
              label: store.address.city,
              value: store.address.city,
            }
          : undefined;
        setSelectedCity(city);

        setMinimumDeliveryBalance(Number(store.minimum_delivery_balance) || 50);
        setMaximumDeliveryKilometer(
          Number(store.maximum_delivery_kilometer) || 30
        );
        setDefaultFreight(Number(store.default_freight) || 0);
        setInstallmentLimitAmount(Number(store.installment_limit_amount) || 0);
        setMottuUnitCode(Number(store.mottu_unit_code) || 0);

        if (store.payment_gateway === 'cielo') {
          setSelectedGateway({ value: 'cielo', label: 'Cielo' });
        } else if (store.payment_gateway === 'pagarme' && store.use_malga) {
          setSelectedGateway({ value: 'pagarme', label: 'Pagar.me v4' });
        } else if (store.payment_gateway === 'pagarme' && !store.use_malga) {
          setSelectedGateway({ value: 'pagarme', label: 'Pagar.me v5' });
        } else if (store.payment_gateway === 'pagarme_hub') {
          setSelectedGateway({ value: 'pagarme_hub', label: 'Pagar.me Hub' });
        } else if (store.payment_gateway === 'mercadopago') {
          setSelectedGateway({ label: 'Mercado Pago', value: 'mercadopago' });
        } else if (store.payment_gateway === 'rede') {
          setSelectedGateway({ label: 'Rede', value: 'rede' });
        } else if (store.payment_gateway === 'pagseguro') {
          setSelectedGateway({ label: 'PagSeguro', value: 'pagseguro' });
        } else if (store.payment_gateway === 'justa') {
          setSelectedGateway({ label: 'Justa', value: 'justa' });
        } else if (store.payment_gateway === '') {
          setSelectedGateway({ label: 'Nenhum', value: '' });
        }

        if (store.bnpl_payment_gateway === 'koin') {
          setSelectedBnplGateway({ label: 'Koin', value: 'koin' });
        }

        setJustaAliasName('filial');
        setJustaCnae(store?.justa_establishment?.cnae);
        setJustaConstitutionDate(store?.justa_establishment?.constitution_date);
        setJustaOwnerName(store?.justa_establishment?.owner?.name);
        setJustaOwnerCpf(store?.justa_establishment?.owner?.legal_document);
        setJustaOwnerEmail(store?.justa_establishment?.owner?.email);
        setJustaOwnerCellphone(store?.justa_establishment?.owner?.cellphone);
        setJustaOwnerPhone(store?.justa_establishment?.owner?.cellphone);
        setJustaOwnerBirthDate(store?.justa_establishment?.owner?.birth_date);
        setJustaOwnerRg(store?.justa_establishment?.owner?.rg);
        setJustaAccountCpf(
          store?.justa_establishment?.account_bank?.legal_document
        );
        setJustaAccountCode(
          store?.justa_establishment?.account_bank?.bank_code
        );
        setSelectedAccountType(store?.justa_establishment?.account_bank?.type);
        setJustaAccountAgency(store?.justa_establishment?.account_bank?.agency);
        setJustaAccountOwnerName(
          store?.justa_establishment?.account_bank?.owner_name
        );
        setJustaAccountNumber(store?.justa_establishment?.account_bank?.number);

        if (store?.justa_establishment?.account_bank?.type === 'checking') {
          setSelectedAccountType({ label: 'Corrente', value: 'checking' });
        }

        if (store?.justa_establishment?.account_bank?.type === 'saving') {
          setSelectedAccountType({ label: 'Poupança', value: 'saving' });
        }

        setExternalGateway(store.use_malga || false);
        setClearsaleAppName(store.clearsale_app);
        setSplitPayment(store.use_split);
        setPagarmeHub(store.pagarme_hub_callback);

        if (store.gerencianet_certificate) {
          setCertificate(store.gerencianet_certificate);
          setCertificateUrl(store.gerencianet_certificate.url);
        }

        setLoading(false);
      } catch (err) {
        history.goBack();
        console.error(err);
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Loja não encontrada.');
        }
      }
    };

    loadStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store_id]);

  const adjustMask = v => {
    const digits = !v ? '' : v.replace(/[^\d]/g, '');
    if (!digits || digits.length < 10) return v;
    const cut = digits.length === 10 ? 6 : 7;
    const max = digits.length > 11 ? 11 : digits.length;
    return `(${digits.substring(0, 2)}) ${digits.substring(
      2,
      cut
    )}-${digits.substring(cut, max)}`;
  };

  const maskBuilder = v => {
    if (!v || v.length === 0) return '';
    const mask = adjustMask(v);
    return mask.length >= 6 && mask[5] === '9'
      ? '(99) 99999-9999'
      : '(99) 9999-9999';
  };

  const handleValidSubmit = async data => {
    try {
      formRef.current.setErrors({});
      const brandLogo = file;
      data = {
        ...data,
        online_payments_value_limit: onlinePaymentLimit,
        minimum_delivery_balance: minimumDeliveryBalance,
        default_freight: defaultFreight,
        installment_limit_amount: installmentLimitAmount,
        address: {
          ...data.address,
          city: selectedCity.value,
          state: selectedState.value,
        },
        customize: {
          theme_color: color,
        },
      };

      const maskTelephoneLength = maskBuilder(telephoneV).length;
      const maskFinancierTelephoneLength = maskBuilder(financierPhone).length;
      const maskJustaOwnerCellphoneLength =
        maskBuilder(justaOwnerCellphone).length;

      await SchemaValidator(
        maskTelephoneLength,
        maskFinancierTelephoneLength,
        maskJustaOwnerCellphoneLength
      ).validate(data, {
        abortEarly: false,
        context: {
          payment_gateway: selectedGateway.value,
        },
      });

      const store = {
        ...data,
        use_malga: externalGateway,
        payment_gateway: selectedGateway.value,
        bnpl_payment_gateway: selectedBnplGateway.value,
        clearsale_app: clearsaleAppName,
        use_split: splitPayment,
        pagarme_hub_key: pagarmeHub?.access_token,
        pagarme_hub_callback: pagarmeHub,
      };

      // Adicionar o objeto justa_establishment se o gateway for Justa
      if (selectedGateway.value === 'justa') {
        store.justa_establishment = {
          alias_name: justaAliasName,
          cnae: justaCnae,
          constitution_date: justaConstitutionDate,
          owner: {
            name: justaOwnerName,
            legal_document: justaOwnerCpf,
            email: justaOwnerEmail,
            cellphone: justaOwnerCellphone,
            phone: justaOwnerPhone,
            birth_date: justaOwnerBirthDate,
            rg: justaOwnerRg || '',
          },
          account_bank: {
            legal_document: justaAccountCpf,
            bank_code: justaAccountCode,
            type: selectedAccountType?.value,
            agency: justaAccountAgency,
            owner_name: justaAccountOwnerName,
            number: justaAccountNumber,
          },
        };
      }

      if (selectedAntifraud.value === 'nenhum') {
        store.clearsale_name = null;
        store.clearsale_password = null;
      }

      if (certificate) {
        try {
          if (certificateUrl && certificate.url) {
            await api.post(
              `administrator/stores/${store_id}/gerencianet/set-certificate`,
              // formData
              { certificate: certificate.url }
            );
          } else {
            const formData = new FormData();
            // const Object.keys(certificate).map(key => formData.append(key, certificate[key]));
            formData.append('certificate', certificate);
            await api.post(
              `administrator/stores/${store_id}/gerencianet/set-certificate`,
              formData
            );
          }
        } catch (err) {
          console.log(err.response.data.certificate);
          if (err.response && err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          }
        }
      }

      await api.put(`administrator/stores/${store_id}`, store);

      if (removeLogo) {
        await api.post(`administrator/stores/${store_id}/brand-logo`);
      }

      if (brandLogo) {
        try {
          const formData = new FormData();

          formData.append('brand_logo', brandLogo);

          await api.post(
            `administrator/stores/${store_id}/brand-logo`,
            formData
          );
        } catch (err) {
          console.log(err.response.data.certificate);
          if (err.response && err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          }
        }
      }

      toast.success('Loja atualizada com sucesso!');

      history.push(`/lojas`);
    } catch (err) {
      console.log(err);
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }

      if (err.response && err.response.data.cnpj) {
        formRef.current.setFieldError('cnpj', err.response.data.cnpj[0]);
      }

      if (err.response && err.response.data.certificate) {
        toast.error(err.response.data.certificate[0]);
      }
    }
  };

  const searchCEP = async () => {
    const cep = formRef.current.getFieldValue('address.cep');

    if (searchedCEP === cep) return;
    setSearchedCEP(cep);
    try {
      toastId.current = toast.info(`Buscando por cep: ${cep}...`, {
        autoClose: false,
      });
      const { data } = await api.get(`cep/${cep}`);

      const formData = formRef.current.getData();
      toast.update(toastId.current, {
        render: 'Endereço encontrado!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        transition: Flip,
      });

      formRef.current.setData({
        ...formData,
        address: { ...data },
      });

      const state = stateList.find(s => s.value === data.state);

      setSelectedState(state);

      const city = {
        label: data.city,
        value: data.city,
      };
      setSelectedCity(city);
    } catch (err) {
      toast.update(toastId.current, {
        render: 'Endereço não encontrado!',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        transition: Flip,
      });
    }
  };

  // const loadOptions = async (inputValue, cb) => {
  //   try {
  //     if (inputValue.length < 2) return;
  //     const {
  //       data: { store_groups },
  //     } = await api.get('/administrator/store-groups', {
  //       params: { query: inputValue },
  //     });

  //     if (store_groups.length === 0) {
  //       return;
  //     }

  //     const groupsSearched = store_groups.map(group => ({
  //       value: group.id,
  //       label: group.name,
  //     }));

  //     setDeliveryGroups(prev => [...prev, ...store_groups]);

  //     cb(groupsSearched);
  //   } catch (err) {
  //     toast.error('Houve um erro ao carregar a lista de produtos');
  //   }
  // };

  // const setGroupSelected = option => {
  //   if (option) {
  //     const group = deliveryGroups.find(p => p.id === option.value);

  //     if (!group) return;

  //     setSelectedGroup(option);

  //     formRef.current.setFieldValue('store_group_id', option);
  //   } else {
  //     setSelectedGroup(null);
  //   }
  // };

  useEffect(() => {
    if (noNumber) {
      formRef?.current?.setFieldValue('address.number', 'sn');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noNumber]);

  const pagarmePublicKey = process.env.REACT_APP_PAGARME_HUB_PUBLIC_KEY;

  const getPagarmeAuthToken = useCallback(
    async authorizationCode => {
      const { data } = await axios.post(
        process.env.REACT_APP_PAGARME_HUB_API_URL,
        {
          code: authorizationCode,
          hub_callback_url: `https://${localStorage.getItem(
            '@VIDDY:X-Server'
          )}.viddy.com.br/api/v1/webhooks/pagarme/hub-callback`,
        },
        {
          headers: {
            PublicAppKey: pagarmePublicKey,
          },
        }
      );

      setPagarmeHub(data);

      return data;
    },
    [pagarmePublicKey]
  );

  const location = useLocation();

  useEffect(() => {
    const searchParams = new window.URLSearchParams(location.search);

    const storeFormState = JSON.parse(sessionStorage.getItem('storeFormState'));

    const authorizationCode = searchParams.get('authorization_code');

    if (!storeFormState || !formRef.current || !authorizationCode) return;

    getPagarmeAuthToken(authorizationCode);

    formRef.current.setData({
      ...formRef.current.getData(),
      ...storeFormState,
    });

    setInitialStore({
      ...formRef.current.getData(),
      ...storeFormState,
    });

    if (storeFormState.city) setSelectedCity(storeFormState.city);
    if (storeFormState.state) setSelectedState(storeFormState.state);
    if (storeFormState.noNumber) setNoNumber(storeFormState.noNumber);
    if (storeFormState.brand_logo) setFile(storeFormState.brand_logo);
    if (storeFormState.theme_color) setColor(storeFormState.theme_color);
    if (storeFormState.use_malga) setExternalGateway(storeFormState.use_malga);
    if (storeFormState.payment_gateway)
      setSelectedGateway(storeFormState.payment_gateway);
    if (storeFormState.clearsale_app)
      setClearsaleAppName(storeFormState.clearsale_app);
    if (storeFormState.use_split) setSplitPayment(storeFormState.use_split);
    if (storeFormState.antifraud)
      setSelectedAntifraud(storeFormState.antifraud);
    if (storeFormState.financierPhone)
      setFinancierPhone(storeFormState.financierPhone);
    if (storeFormState.minimumDeliveryBalance)
      setMinimumDeliveryBalance(storeFormState.minimumDeliveryBalance);
    if (storeFormState.onlinePaymentLimit)
      setOnlinePaymentLimit(storeFormState.onlinePaymentLimit);
    if (storeFormState.maximumDeliveryKilometer)
      setMaximumDeliveryKilometer(storeFormState.maximumDeliveryKilometer);
    if (storeFormState.defaultFreight)
      setDefaultFreight(storeFormState.defaultFreight);
    if (storeFormState.installmentLimitAmount)
      setInstallmentLimitAmount(storeFormState.installmentLimitAmount);
    if (storeFormState.mottuUnitCode)
      setMottuUnitCode(storeFormState.mottuUnitCode);
    if (storeFormState.extraPersistenceFields)
      setExtraPersistenceFields(storeFormState.extraPersistenceFields);

    if (storeFormState.justa_cnae) setJustaCnae(storeFormState.justa_cnae);
    if (storeFormState.justa_constitution_date)
      setJustaConstitutionDate(storeFormState.justa_constitution_date);
    if (storeFormState.justa_owner_name)
      setJustaOwnerName(storeFormState.justa_owner_name);
    if (storeFormState.justa_owner_cpf)
      setJustaOwnerCpf(storeFormState.justa_owner_cpf);
    if (storeFormState.justa_owner_email)
      setJustaOwnerEmail(storeFormState.justa_owner_email);
    if (storeFormState.justa_owner_cellphone)
      setJustaOwnerCellphone(storeFormState.justa_owner_cellphone);
    if (storeFormState.justa_owner_cellphone)
      setJustaOwnerPhone(storeFormState.justa_owner_cellphone);
    if (storeFormState.justa_owner_birth_date)
      setJustaOwnerBirthDate(storeFormState.justa_owner_birth_date);
    if (storeFormState?.justa_owner_rg)
      setJustaOwnerRg(storeFormState.justa_owner_rg);
    if (storeFormState.justa_owner_cpf)
      setJustaAccountCpf(storeFormState.justa_owner_cpf);
    if (storeFormState.justa_account_bank_code)
      setJustaAccountCode(storeFormState.justa_account_bank_code);
    if (storeFormState.justa_account_bank_type)
      setSelectedAccountType(storeFormState.justa_account_bank_type);
    if (storeFormState.justa_account_bank_agency)
      setJustaAccountAgency(storeFormState.justa_account_bank_agency);
    if (storeFormState.justa_account_bank_owner_name)
      setJustaAccountOwnerName(storeFormState.justa_account_bank_owner_name);
    if (storeFormState.justa_account_bank_number)
      setJustaAccountNumber(storeFormState.justa_account_bank_number);

    sessionStorage.clear();
  }, [initialStore, getPagarmeAuthToken, location.search, loading]);

  useEffect(() => {
    const visibilitychangeHandler = async () => {
      if (document.hidden) return;

      const {
        data: { store },
      } = await api.get(`stores/${store_id}`);

      setPagarmeHub(store.pagarme_hub_callback);
    };

    document.addEventListener('visibilitychange', visibilitychangeHandler);

    return () =>
      document.removeEventListener('visibilitychange', visibilitychangeHandler);
  }, [store_id]);

  const pagarmeUrl = pagarmeHub?.install_id
    ? {
        href: `${process.env.REACT_APP_PAGARME_HUB_URL}/${pagarmePublicKey}/edit/${pagarmeHub?.install_id}`,
        target: '_blank',
        rel: 'noreferrer',
      }
    : {
        href: `${process.env.REACT_APP_PAGARME_HUB_URL}/${pagarmePublicKey}/authorize?redirect=${window.location.href}`,
      };

  return (
    <div className="page-content">
      <Container className="container new-layout">
        {loading ? (
          <Loading />
        ) : (
          <>
            <PageHeader
              pageTitle="EDITAR LOJA"
              backTo="/lojas"
              responsivePosition="start"
              desktopPosition="center"
            />
            <Form
              onSubmit={handleValidSubmit}
              ref={formRef}
              initialData={
                initialStore && {
                  ...initialStore,
                  bank_data: {
                    ...initialStore.bank_data,
                    institution: {
                      value: initialStore.bank_data?.institution_code || '',
                      label:
                        initialStore.bank_data?.institution || 'Instituição',
                    },
                    document_type: {
                      value: initialStore.bank_data?.document_type || '',
                      label:
                        initialStore.bank_data?.document_type ||
                        'Selecione o tipo do documento',
                    },
                  },
                }
              }
              noValidate
            >
              <Card className="card new-layout">
                <CardBody className="card-body new-layout">
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Dados da Loja
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Razão Social</Label>
                        <Input
                          name="name"
                          placeholder="Razão Social"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Nome Fantasia</Label>
                        <Input
                          name="fantasy_name"
                          placeholder="Nome Fantasia"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>CNPJ</Label>
                        <InputMask
                          name="cnpj"
                          placeholder="CNPJ"
                          mask="99.999.999/9999-99"
                          maskChar={null}
                          type="text"
                          value={extraPersistenceFields.cnpj}
                          onChange={e =>
                            setExtraPersistenceFields(state => ({
                              ...state,
                              cnpj: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Nome do Responsável</Label>
                        <Input
                          name="responsible_name"
                          placeholder="Nome do Responsável"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>E-mail</Label>
                        <Input name="email" placeholder="E-mail" type="text" />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Telefone</Label>
                        <InputMask
                          mask={maskBuilder(telephoneV)}
                          maskChar={null}
                          defaultValue={telephoneV}
                          name="telephone"
                          placeholder="Telefone"
                          type="text"
                          onChange={e => setTelephone(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Scope path="address">
                    <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                      Endereço
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col className="px-0">
                        <FormGroup>
                          <Label>CEP</Label>
                          <InputMask
                            name="cep"
                            placeholder="CEP"
                            mask="99999-999"
                            maskChar={null}
                            value={extraPersistenceFields.cep}
                            onChange={e => {
                              if (e.target.value.length === 9) searchCEP();

                              setExtraPersistenceFields(state => ({
                                ...state,
                                cep: e.target.value,
                              }));
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Logradouro</Label>
                          <Input
                            name="street_name"
                            placeholder="Logradouro"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <Label>Número</Label>

                        <CustomInput
                          type="switch"
                          id="noNumberSwitch"
                          name="noNumber"
                          label="Sem Número"
                          checked={noNumber}
                          className="text-primary float-right"
                          onChange={() => setNoNumber(!noNumber)}
                        />
                        <FormGroup>
                          <Input
                            name="number"
                            placeholder="Número"
                            type="tel"
                            disabled={noNumber}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Bairro</Label>
                          <Input
                            name="neighborhood"
                            placeholder="Bairro"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <FormGroup>
                          <Label>Complemento</Label>
                          <Input
                            name="complement"
                            placeholder="Complemento"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Estado</Label>
                          <InputSelect
                            name="state"
                            options={stateList}
                            onChange={selectedOption =>
                              setSelectedState(selectedOption)
                            }
                            value={selectedState}
                            placeholder="Estado"
                            singlePath
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <FormGroup>
                          <Label>Cidade</Label>
                          <InputSelect
                            name="city"
                            options={cityList}
                            onChange={selectedOption =>
                              setSelectedCity(selectedOption)
                            }
                            value={selectedCity}
                            placeholder="Cidade"
                            isDisabled={!selectedState}
                            singlePath
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0 mb-4">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Informações Adicionais</Label>
                          <Input
                            name="additional_information"
                            placeholder="Informações Adicionais"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pl-lg-2">
                        <FormGroup>
                          <Label>Telefone do Financeiro</Label>
                          <InputMask
                            mask={maskBuilder(financierPhone)}
                            maskChar={null}
                            value={financierPhone}
                            name="financier_telephone"
                            placeholder="Celular do financeiro para comunicação mais rápida"
                            type="text"
                            onChange={e => setFinancierPhone(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Scope>
                  {/* <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>E-mail do Financeiro</Label>
                        <Input
                          name="financier_email"
                          placeholder="E-mail do financeiro"
                          type="text"
                          tip="Necessário para envio de comprovantes de transferência
                          para aprovação"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <Scope path="customize">
                    <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                      Customização por marca
                    </Row>
                    <Row className="w-100 mx-0">
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>
                            Logo que vai aparecer no link de pagamento
                          </Label>
                          <ImageInput
                            name="brand_logo"
                            logo={logo}
                            setLogo={setLogo}
                            setFile={setFile}
                            setRemoveLogo={setRemoveLogo}
                          />
                          <span className="font-weight-400 font-size-12">
                            Recomendamos que seja em png transparente
                          </span>
                        </FormGroup>
                      </Col>
                      <Col lg={6} className="px-0 pr-lg-2">
                        <FormGroup>
                          <Label>Cor do header do link de pagamento</Label>
                          <ColorPicker
                            color={initialStore?.customize?.theme_color}
                            setColor={setColor}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Scope>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Informações Técnicas
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>
                          Limite de valor para pagamentos online
                          <i
                            id="onlinePaymentsLimit"
                            className="las la-info-circle font-size-18 ml-1"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="onlinePaymentsLimit"
                          >
                            Valor máximo de um pagamento online para essa loja,
                            Digite 0 para ilimitado.
                          </UncontrolledTooltip>
                        </Label>
                        <IntlCurrencyInput
                          currency="BRL"
                          onChange={(_, limit) => {
                            setOnlinePaymentLimit(limit);
                          }}
                          name="online_payments_value_limit"
                          id="online_payments_value_limit"
                          placeholder="Limite de pagamento online"
                          className="w-100 form-control"
                          value={onlinePaymentLimit}
                          config={{
                            locale: 'pt-BR',
                            formats: {
                              number: {
                                BRL: {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              },
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Saldo mínimo no serviço de entrega</Label>
                        <i
                          id="stdMnimumBalance"
                          className="las la-info-circle font-size-18 ml-1"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="stdMnimumBalance"
                        >
                          Mínimo de R$ 50,00
                        </UncontrolledTooltip>
                        <IntlCurrencyInput
                          currency="BRL"
                          onChange={(_, minimum) => {
                            setMinimumDeliveryBalance(minimum);
                          }}
                          value={minimumDeliveryBalance}
                          name="minimum_delivery_balance"
                          id="minimum_delivery_balance"
                          placeholder="Saldo mínimo no serviço de entrega"
                          className="w-100 form-control"
                          config={{
                            locale: 'pt-BR',
                            formats: {
                              number: {
                                BRL: {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              },
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>
                          Valor de frete padrão
                          <i
                            id="stdDefaultFreight"
                            className="las la-info-circle font-size-18 ml-1"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="stdDefaultFreight"
                          >
                            O valor informado nesse campo será utilizado no
                            fluxo de nova venda.
                          </UncontrolledTooltip>
                        </Label>
                        <IntlCurrencyInput
                          currency="BRL"
                          onChange={(_, freight) => {
                            setDefaultFreight(freight);
                          }}
                          name="default_freight"
                          id="default_freight"
                          placeholder="Valor de frete padrão"
                          className="w-100 form-control"
                          value={defaultFreight}
                          config={{
                            locale: 'pt-BR',
                            formats: {
                              number: {
                                BRL: {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              },
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Valor da parcela mínima</Label>
                        <i
                          id="stdInstallmentLimit"
                          className="las la-info-circle font-size-18 ml-1"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="stdInstallmentLimit"
                        >
                          Se este campo estiver preenchido, toda venda terá um
                          valor limite da parcela, dessa forma vai ser exibido o
                          limite de quantidade de parcelas usando esse valor
                          como base.
                        </UncontrolledTooltip>
                        <IntlCurrencyInput
                          currency="BRL"
                          onChange={(_, installmentLimit) => {
                            setInstallmentLimitAmount(installmentLimit);
                          }}
                          value={installmentLimitAmount}
                          name="installment_limit_amount"
                          id="installment_limit_amount"
                          placeholder="Valor da parcela mínima"
                          className="w-100 form-control"
                          config={{
                            locale: 'pt-BR',
                            formats: {
                              number: {
                                BRL: {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                },
                              },
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>
                          Distância máxima em KM para efetuar entregas
                        </Label>
                        <Input
                          name="maximum_delivery_kilometer"
                          placeholder="Distância em KM"
                          type="number"
                          value={maximumDeliveryKilometer}
                          onChange={e =>
                            setMaximumDeliveryKilometer(e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Código da Unidade</Label>
                        <i
                          id="userMottuId"
                          className="las la-info-circle font-size-18 ml-1"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="userMottuId"
                        >
                          Caso a loja utilize o serviço da Mottu Delivery.
                        </UncontrolledTooltip>
                        <Input
                          name="mottu_unit_code"
                          placeholder="Informe o código da sua unidade"
                          type="number"
                          defaultValue={mottuUnitCode}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Informações de Pagamento
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <Label>Gateway de pagamento</Label>

                      <CustomInput
                        type="switch"
                        id="externalSwitch"
                        name="externalGateway"
                        label="Externo"
                        checked={externalGateway}
                        className="text-primary float-right"
                        onChange={() => {
                          setExternalGateway(state => !state);
                          setSelectedGateway(gatewayOptions[0]);
                        }}
                      />
                      <FormGroup>
                        <InputSelect
                          name="payment_gateway"
                          className="common-select"
                          options={
                            externalGateway
                              ? externalGatewayOptions
                              : gatewayOptions
                          }
                          onChange={selectedOption => {
                            setSelectedGateway(selectedOption);
                          }}
                          value={selectedGateway}
                          placeholder="Gateway switch"
                          singlePath
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>Antifraude</Label>
                        <InputSelect
                          name="payment_gateway"
                          className="common-select"
                          options={antifraudOptions}
                          value={selectedAntifraud}
                          onChange={selectedOption => {
                            setSelectedAntifraud(selectedOption);
                          }}
                          placeholder="Selecione antifraude"
                          singlePath
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {selectedAntifraud.value === 'clearsale' && (
                    <>
                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                        ClearSale
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Usuário do ClearSale</Label>
                            <Input
                              name="clearsale_name"
                              placeholder="Usuário"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Senha do ClearSale</Label>
                            <Input
                              name="clearsale_password"
                              placeholder="Senha"
                              type="password"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Nome do App</Label>
                            <Input
                              onChange={e =>
                                setClearsaleAppName(e.target.value)
                              }
                              value={clearsaleAppName}
                              name="clearsale_app"
                              placeholder="Nome do App"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                  {selectedGateway.value === 'justa' && (
                    <>
                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-2">
                        Dados Empresariais
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>CNAE</Label>
                            <Input
                              name="justa_cnae"
                              value={justaCnae || ''}
                              onChange={({ target: { value } }) => {
                                setJustaCnae(value);
                              }}
                              placeholder="CNAE"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>Data de Criação</Label>
                            <InputMask
                              name="justa_constitution_date"
                              placeholder="Data de Criação"
                              mask="99/99/9999"
                              maskChar={null}
                              value={justaConstitutionDate || ''}
                              onChange={({ target: { value } }) => {
                                setJustaConstitutionDate(value);
                              }}
                              onBlur={e =>
                                isConstitutionDateValid(e.target.value)
                              }
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-2">
                        Dados do Responsável
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Nome do Sócio</Label>
                            <Input
                              name="justa_owner_name"
                              placeholder="Nome do Responsável"
                              value={justaOwnerName || ''}
                              onChange={({ target: { value } }) => {
                                setJustaOwnerName(value);
                              }}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>Data de Nascimento</Label>
                            <InputMask
                              name="justa_owner_birth_date"
                              placeholder="Data de Nascimento"
                              mask="99/99/9999"
                              value={justaOwnerBirthDate || ''}
                              onChange={({ target: { value } }) => {
                                setJustaOwnerBirthDate(value);
                              }}
                              maskChar={null}
                              onBlur={e =>
                                isOwnerBirthDateValid(e.target.value)
                              }
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>RG</Label>
                            <Input
                              name="justa_owner_rg"
                              placeholder="RG"
                              value={justaOwnerRg || ''}
                              onChange={({ target: { value } }) => {
                                setJustaOwnerRg(value);
                              }}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>CPF</Label>
                            <InputMask
                              name="justa_owner_cpf"
                              placeholder="CPF"
                              mask="999.999.999-99"
                              value={justaOwnerCpf || ''}
                              onChange={({ target: { value } }) => {
                                setJustaOwnerCpf(value);
                              }}
                              maskChar={null}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>E-mail</Label>
                            <Input
                              name="justa_owner_email"
                              type="text"
                              placeholder="E-mail"
                              value={justaOwnerEmail || ''}
                              onChange={({ target: { value } }) => {
                                setJustaOwnerEmail(value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>Celular</Label>
                            <InputMask
                              mask={maskBuilder(justaOwnerCellphone)}
                              maskChar={null}
                              name="justa_owner_cellphone"
                              placeholder="Celular"
                              type="text"
                              value={justaOwnerCellphone || ''}
                              onChange={({ target: { value } }) => {
                                if (value.length < 15 || value[5] === '9') {
                                  setJustaOwnerCellphone(value);
                                }
                              }}
                              onBlur={({ target: { value } }) => {
                                if (
                                  value.length <
                                  maskBuilder(justaOwnerCellphone).length
                                ) {
                                  console.warn('Número incompleto:', value);
                                }
                                setJustaOwnerCellphone(value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-2">
                        Dados Bancários
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Tipo de Conta</Label>
                            <InputSelect
                              name="justa_account_bank_type"
                              options={accountTypes}
                              value={selectedAccountType || ''}
                              onChange={selectedOption => {
                                const selected = accountTypes.find(
                                  option =>
                                    option.value === selectedOption.value
                                );
                                setSelectedAccountType(selected);
                              }}
                              placeholder="Selecione o Tipo de Conta"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>Código do Banco</Label>
                            <Input
                              name="justa_account_bank_code"
                              type="number"
                              placeholder="Código do Banco"
                              value={justaAccountCode || ''}
                              onChange={({ target: { value } }) => {
                                setJustaAccountCode(value);
                              }}
                              maxLength="3"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Agência</Label>
                            <Input
                              name="justa_account_bank_agency"
                              type="string"
                              placeholder="Agência"
                              value={justaAccountAgency || ''}
                              onChange={({ target: { value } }) => {
                                setJustaAccountAgency(value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>Número da Conta</Label>
                            <Input
                              name="justa_account_bank_number"
                              type="string"
                              placeholder="Número da Conta"
                              value={justaAccountNumber || ''}
                              onChange={e => {
                                const rawValue = e.target.value.replace(
                                  /\D/g,
                                  ''
                                );

                                const formattedValue =
                                  rawValue.length > 1
                                    ? `${rawValue.slice(
                                        0,
                                        -1
                                      )}-${rawValue.slice(-1)}`
                                    : rawValue;

                                e.target.value = formattedValue;
                                setJustaAccountNumber(formattedValue);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>CPF</Label>
                            <InputMask
                              name="justa_account_bank_cpf"
                              type="text"
                              placeholder="CPF"
                              mask="999.999.999-99"
                              value={justaAccountCpf || ''}
                              onChange={({ target: { value } }) => {
                                setJustaAccountCpf(value);
                              }}
                              maskChar={null}
                              onBlur={async e => {
                                try {
                                  await cpfSchema.validate(e.target.value);
                                } catch (err) {
                                  formRef.current.setFieldError(
                                    'justa_account_bank_cpf',
                                    err.message
                                  );
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>Responsável pela Conta</Label>
                            <Input
                              name="justa_account_bank_owner_name"
                              type="text"
                              placeholder="Responsável pela Conta"
                              value={justaAccountOwnerName || ''}
                              onChange={({ target: { value } }) => {
                                setJustaAccountOwnerName(value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                  {selectedGateway.value === 'cielo' && (
                    <>
                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                        Gerência NET
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Client ID</Label>
                            <Input
                              name="gerencianet_client_id"
                              placeholder="Client ID"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>Client Secret</Label>
                            <Input
                              name="gerencianet_client_secret"
                              placeholder="Client Secret"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Chave Pix</Label>
                            <Input
                              name="pix_key"
                              placeholder="Chave Pix"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup className="col px-0">
                            <Label className="d-block">Certificado PEM</Label>
                            <Row
                              className={`w-100 mx-0 d-block font-weight-500 text-${
                                certificate ? 'success' : 'gray-700'
                              }`}
                            >
                              <div className="d-inline">
                                {certificate?.name ||
                                  'Nenhum arquivo selecionado'}
                              </div>
                              {certificate && (
                                <IconButton
                                  id="clean-certificate"
                                  icon="las la-times"
                                  color="danger"
                                  click={() => {
                                    certificateRef.current.value = null;
                                    setCertificate(null);
                                  }}
                                  tip="Limpar"
                                  forceTip
                                  fontSize={18}
                                  containerClass="d-inline ml-2"
                                />
                              )}
                              <a
                                onClick={() => certificateRef.current?.click()}
                                className="mt-2 d-block"
                              >
                                {certificate
                                  ? 'Escolher outro arquivo'
                                  : 'Fazer Upload'}
                              </a>
                            </Row>
                            <input
                              type="file"
                              id="certificate"
                              accept=".pem"
                              ref={certificateRef}
                              className="d-none"
                              onChange={e => {
                                const { files } = e.target;
                                try {
                                  setCertificate(files[0]);
                                } catch (err) {
                                  if (
                                    err.response &&
                                    err?.response?.data?.message
                                  ) {
                                    toast.error(err?.response?.data?.message);
                                  }
                                  console.log(err);
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                        Cielo
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Merchant ID</Label>
                            <Input
                              name="cielo_merchant_id"
                              placeholder="Merchant ID"
                              type="text"
                              defaultValue={cieloMerchantId}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>Merchant Key</Label>
                            <Input
                              name="cielo_merchant_key"
                              placeholder="Merchant Key"
                              type="text"
                              defaultValue={cieloMerchantKey}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>MID Cybersource (AntiFraude)</Label>
                            <Input
                              name="cielo_provider_id"
                              placeholder="Provider ID"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                        Chaves da Cielo
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Client ID</Label>
                            <Input
                              name="clientId"
                              placeholder="Client ID"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>Client Secret</Label>
                            <Input
                              name="secret"
                              placeholder="Client Secret"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row> */}
                      {/* <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Provider ID</Label>
                            <Input
                              name="cielo_provider_id"
                              placeholder="Provider ID"
                              type="text"
                              // defaultValue={cieloClientId}
                            />
                          </FormGroup>
                        </Col>
                      </Row> */}
                    </>
                  )}{' '}
                  {['pagarme', 'pagarme_hub'].includes(
                    selectedGateway.value
                  ) && (
                    <>
                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                        <Col lg={6} className="px-0 pr-lg-2">
                          Pagar.me
                        </Col>
                      </Row>
                      {!externalGateway &&
                      selectedGateway?.value === 'pagarme_hub' ? (
                        <Row className="w-100 mx-0">
                          <Col lg={6} className="px-0 pr-lg-2">
                            <FormGroup>
                              <Button
                                color="primary"
                                size="lg"
                                type="button"
                                className="new-layout-btn new-layout-btn-transparent main d-flex align-items-center px-0 w-100"
                                onClick={() => {
                                  let data = formRef.current.getData();
                                  data = {
                                    ...data,
                                    ...extraPersistenceFields,
                                    city: selectedCity,
                                    state: selectedState,
                                    telephone: telephoneV,
                                    minimum_delivery_balance:
                                      data.minimum_delivery_balance,
                                    maximum_delivery_kilometer:
                                      data.maximum_delivery_kilometer,
                                    online_payments_value_limit:
                                      data.online_payments_value_limit,
                                    default_freight: data.default_freight,
                                    installment_limit_amount:
                                      data.installment_limit_amount,
                                    brand_logo: file,
                                    theme_color: color,
                                    use_malga: externalGateway,
                                    payment_gateway: selectedGateway,
                                    clearsale_app: clearsaleAppName,
                                    use_split: splitPayment,
                                    antifraud: selectedAntifraud,
                                    onlinePaymentLimit,
                                    minimumDeliveryBalance,
                                    maximumDeliveryKilometer,
                                    defaultFreight,
                                    installmentLimitAmount,
                                    mottuUnitCode,
                                    financierPhone,
                                    noNumber,
                                  };
                                  sessionStorage.setItem(
                                    'storeFormState',
                                    JSON.stringify(data)
                                  );
                                }}
                              >
                                <a
                                  {...pagarmeUrl}
                                  className="text-primary w-100 h-100"
                                >
                                  <img
                                    src="https://pagar.me/favicon-32x32.png"
                                    alt="Ícone do pagar.me"
                                    style={{ marginTop: '-2px' }}
                                    width={24}
                                    height={24}
                                  />
                                  <span className="ml-3">
                                    {pagarmeHub?.install_id
                                      ? 'Visualizar Integração'
                                      : 'Integrar com Pagarme'}
                                  </span>
                                </a>
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}
                      {!externalGateway &&
                      selectedGateway?.value === 'pagarme' ? (
                        <Row className="w-100 mx-0">
                          <Col lg={6} className="px-0 pr-lg-2">
                            <FormGroup>
                              <Label>Chave Pública</Label>
                              <CustomInput
                                type="switch"
                                id="split"
                                name="split"
                                label="Split"
                                checked={splitPayment}
                                className="text-primary float-right"
                                onChange={() => setSplitPayment(!splitPayment)}
                              />
                              <Input
                                name="pagarme_api_public_key"
                                placeholder="Public Key"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={6} className="px-0 pl-lg-2">
                            <FormGroup>
                              <Label>Chave Secreta</Label>
                              <Input
                                name="pagarme_api_secret_key"
                                placeholder="Private Key"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}
                      {externalGateway ? (
                        <Row className="w-100 mx-0">
                          <Col xs={12} className="px-0 pr-lg-2">
                            <FormGroup>
                              <Label>Pagar.me Key</Label>
                              <Input
                                name="pagarme_api_key"
                                placeholder="Pagar.me key"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}
                    </>
                  )}
                  {selectedGateway.value === 'pagseguro' && (
                    <>
                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                        PagSeguro
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Token</Label>
                            <Input
                              name="pagseguro_token"
                              placeholder="Token"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>E-mail</Label>
                            <Input
                              name="pagseguro_email"
                              placeholder="E-mail"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                  {selectedGateway.value === 'rede' && (
                    <>
                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                        Gerência NET
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Client ID</Label>
                            <Input
                              name="gerencianet_client_id"
                              placeholder="Client ID"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>Client Secret</Label>
                            <Input
                              name="gerencianet_client_secret"
                              placeholder="Client Secret"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Chave Pix</Label>
                            <Input
                              name="pix_key"
                              placeholder="Chave Pix"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup className="col px-0">
                            <Label className="d-block">Certificado PEM</Label>
                            <Row
                              className={`w-100 mx-0 d-block font-weight-500 text-${
                                certificate ? 'success' : 'gray-700'
                              }`}
                            >
                              <div className="d-inline">
                                {certificate?.name ||
                                  'Nenhum arquivo selecionado'}
                              </div>
                              {certificate && (
                                <IconButton
                                  id="clean-certificate"
                                  icon="las la-times"
                                  color="danger"
                                  click={() => {
                                    certificateRef.current.value = null;
                                    setCertificate(null);
                                  }}
                                  tip="Limpar"
                                  forceTip
                                  fontSize={18}
                                  containerClass="d-inline ml-2"
                                />
                              )}
                              <a
                                onClick={() => certificateRef.current?.click()}
                                className="mt-2 d-block"
                              >
                                {certificate
                                  ? 'Escolher outro arquivo'
                                  : 'Fazer Upload'}
                              </a>
                            </Row>
                            <input
                              type="file"
                              id="certificate"
                              accept=".pem"
                              ref={certificateRef}
                              className="d-none"
                              onChange={e => {
                                const { files } = e.target;
                                try {
                                  setCertificate(files[0]);
                                } catch (err) {
                                  console.log(err);
                                  if (
                                    err.response &&
                                    err?.response?.data?.message
                                  ) {
                                    toast.error(err?.response?.data?.message);
                                  }
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                        Rede
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Merchant ID</Label>
                            <Input
                              name="rede_merchant_id"
                              placeholder="Merchant ID"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>API Key</Label>
                            <Input
                              name="rede_api_key"
                              placeholder="API Key"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                  {selectedGateway.value === 'mercadopago' && (
                    <>
                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                        Mercado Pago
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={12} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Access Key</Label>
                            <Input
                              name="mercadopago_access_token"
                              placeholder="Access Key"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Pix Parcelado
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <Label>Gateway de pagamento</Label>

                      <FormGroup>
                        <InputSelect
                          name="bnpl_payment_gateway"
                          className="common-select"
                          options={bnplGatewayOptions}
                          onChange={selectedOption => {
                            setSelectedBnplGateway(selectedOption);
                          }}
                          value={selectedBnplGateway || ''}
                          placeholder="Gateway switch"
                          singlePath
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {selectedBnplGateway.value === 'koin' && (
                    <>
                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                        Koin
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Chave Privada</Label>
                            <Input
                              name="koin_private_key"
                              placeholder="Chave Privada"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pl-lg-2">
                          <FormGroup>
                            <Label>Número da Conta</Label>
                            <Input
                              name="koin_account"
                              placeholder="Número da Conta"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6} className="px-0 pr-lg-2">
                          <FormGroup>
                            <Label>Identificador da Conta</Label>
                            <Input
                              name="koin_store_code"
                              placeholder="Identificador da Conta"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Informações CRM Bônus
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Código da Empresa</Label>
                        <Input
                          name="crmbonus_company_code"
                          placeholder="Código da Empresa"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>ID da Loja</Label>
                        <Input
                          name="crmbonus_store_id"
                          placeholder="ID da Loja"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Informações Frota Alternativa
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Credenciamento (Mapp)</Label>
                        <Input
                          name="mapp_client"
                          placeholder="Código do Cliente"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Label>E-mail para notificações</Label>
                        <Input
                          name="email_delivery_notification"
                          placeholder="E-mail da loja"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 align-items-end">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>URL de Notificações (Webhook)</Label>
                        <Input
                          name="url_delivery_notification"
                          placeholder="URL para consulta"
                          type="text"
                          disabled
                          value={mappLink}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} className="px-0 pl-lg-2">
                      <FormGroup>
                        <Button
                          color="primary"
                          size="lg"
                          type="button"
                          className="new-layout-btn new-layout-btn-transparent main d-flex align-items-center"
                          onClick={() => {
                            copyStringToClipboard(mappLink, 'URL copiado!');
                          }}
                        >
                          <i className="las la-copy" />
                          Copiar URL
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 my-4">
                    Informações Uber
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-2">
                      <FormGroup>
                        <Label>Customer ID</Label>
                        <Input
                          name="uber_customer_id"
                          placeholder="Customer ID da Loja na Uber"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row className="w-100 mx-0 my-4 ">
                <Col align="end" className="px-0">
                  <Button
                    className="new-layout-btn main"
                    color="primary"
                    size="lg"
                    type="submit"
                  >
                    Salvar Alterações
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Container>
    </div>
  );
};

export default EditStore;
