/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import IconButton from '~/components/IconButton';
import PixDataCard from '~/components/Pix/PixDataCard';
import { LG } from '~/util/breakpointWidths';
import { formatWhatsappPhoneNumber } from '~/util/format';
import { formatDate } from '~/util/formatDate';
import { defineExpandedState } from '~/util/newLayoutHelpers';

export default function PaymentCard({ cart }) {
  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const [expanded, setExpanded] = useState(defineExpandedState(windowSize, LG));

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row className="w-100 mx-0 justify-content-between">
          <Col
            className="w-100 font-size-16 font-weight-600 d-flex align-items-start px-0"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <Row className="w-100 mx-0 ml-lg-2 cursor-pointer justify-content-between align-items-center">
              <div className="px-0">Pagamento</div>
            </Row>
            <IconButton
              icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
              click={() => {
                setExpanded(!expanded);
              }}
              color="gray-700"
            />
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          <Col className="ml-lg-2 px-0">
            <Row className="mx-0 mb-0">
              <Col className="mx-0 px-0">
                <p className="font-size-14 font-weight-600 text-gray-600 mx-0 pt-4 mb-1">
                  {cart?.store?.payment_gateway && 'Cartão / Pix'}
                  {cart?.store?.bnpl_payment_gateway && ' / Pix Parcelado'}
                </p>
                {cart?.url_shortener?.url ? (
                  <a
                    href={cart?.url_shortener?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-black"
                    id="deliveryTrackUrl"
                  >
                    {cart?.url_shortener?.url}
                  </a>
                ) : (
                  'Ainda não foi gerado um link de pagamento'
                )}
              </Col>
            </Row>
            {cart?.url_shortener?.url && (
              <Row className="mx-0 mt-4 mb-0">
                <div className="mx-0 px-0">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`
                                          https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                            cart?.client?.telephone
                                          )}&text=Segue link para pagamento da sua compra: ${
                      cart?.url_shortener?.url
                    }
                                `}
                  >
                    <Button
                      id="sharePaymentURL"
                      color="info"
                      className="new-layout-btn main d-flex align-items-center bg-light-blue border-0 text-primary"
                      outline
                    >
                      <i className="las la-share mr-2" />
                      <span className="font-size-14">Compartilhar</span>
                    </Button>
                  </a>
                </div>
                <div className="mx-0 px-0 ml-4">
                  <Button
                    id="copyPaymentURL"
                    color="info"
                    onClick={() => {
                      const dummy = document.createElement('textarea');
                      document.body.appendChild(dummy);
                      dummy.value = cart?.url_shortener?.url;
                      dummy.select();
                      document.execCommand('copy');
                      document.body.removeChild(dummy);

                      toast.success('Link copiado!');
                    }}
                    className="new-layout-btn main d-flex align-items-center bg-light-blue border-0 text-primary"
                    outline
                  >
                    <i className="las la-copy mr-2" />
                    <span className="font-size-14">Copiar</span>
                  </Button>
                </div>
              </Row>
            )}
          </Col>
          {(cart?.payment_type === 105 ||
            cart?.payment_type === 106 ||
            cart?.payment_type === 110 ||
            cart?.payment_type === 112 ||
            cart?.payment_type === 114 ||
            cart?.payment_type === 118 ||
            ((cart?.payment_type === 115 || cart?.payment_type === 116) &&
              cart?.payment_process?.status === 'Paid')) &&
            cart?.status >= 1 &&
            cart?.payment_process?.qrcode && (
              <Col xs={12} lg={12} className="pl-0 mt-3 ml-lg-1 pr-lg-2">
                <PixDataCard
                  qrCode={
                    cart?.payment_type === 115 || cart?.payment_type === 116
                      ? cart.payment_process?.emv
                      : cart.payment_process?.qrcode
                  }
                  sale={cart}
                  isPaymentCard
                >
                  <Row className="w-100 mx-0 mb-2 mt-4">
                    <Col className="px-0 font-size-14 font-weight-600 text-gray-600">
                      {cart.payment_process?.expiration_date
                        ? `Data de expiração: ${formatDate(
                            cart.payment_process?.expiration_date
                          )}`
                        : 'Sem data de expiração'}
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 mb-0 mt-4">
                    <Col className="px-0 font-size-14 font-weight-600 text-gray-600">
                      Identificador da transação
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 d-flex flex-row align-items-center justify-content-between">
                    <div
                      style={{ maxWidth: '100% !important' }}
                      className="d-inline text-truncate font-size-14 font-weight-400 text-gray-700"
                    >
                      {cart.payment_process?.transaction_id}
                    </div>
                  </Row>
                </PixDataCard>
              </Col>
            )}
        </Collapse>
      </CardBody>
    </Card>
  );
}
