export const activeUsersFilterOptions = [
  { label: 'Usuários Ativos', value: false },
  { label: 'Usuários Inativos', value: true },
];

export const activeStoresFilterOptions = [
  { label: 'Lojas Ativas', value: false },
  { label: 'Lojas Inativas', value: true },
];

export const gatewayOptions = [
  { value: '', label: 'Nenhum' },
  { value: 'pagarme', label: 'Pagar.me v5' },
  { value: 'pagarme_hub', label: 'Pagar.me HUB' },
  { value: 'cielo', label: 'Cielo' },
  { value: 'justa', label: 'Justa' },
];

export const externalGatewayOptions = [
  { value: '', label: 'Nenhum' },
  { value: 'pagarme', label: 'Pagar.me v4' },
  { value: 'rede', label: 'Rede' },
  { value: 'mercadopago', label: 'Mercado Pago' },
  { value: 'pagseguro', label: 'PagSeguro' },
];

export const bnplGatewayOptions = [
  { value: '', label: 'Nenhum' },
  { value: 'koin', label: 'Koin' },
];

export const deliveryStatuses = [
  { value: 'BEE', label: 'Bee' },
  { value: 'MOTTU', label: 'Mottu' },
  { value: 'UBER', label: 'Uber' },
  { value: 'CORREIOS', label: 'Correios' },
  { value: 'LALAMOVE', label: 'Lalamove' },
  { value: 'PROPRIA', label: 'Alternativa' },
];

export const salesStatuses = [
  { value: 0, label: 'Não finalizado', name: '' },
  { value: 1, label: 'Aguardando pagamento', name: 'Em pagamento' },
  {
    value: 14,
    label: 'Aguardando Aceite',
    name: 'WAITING_FOR_ACCEPTANCE',
  },
  { value: 2, label: 'Pagamento Confirmado' },
  {
    value: 3,
    label: 'Aguardando entrega/retirada',
  },
  { value: 4, label: 'Entregue' },
  { value: 6, label: 'Cancelado' },
  {
    value: 7,
    label: 'Pagamento recusado automaticamente',
    name: 'AUTOMATICALLY_REJECTED',
  },
  { value: 8, label: 'Risco baixo', name: 'AUTHORIZED_WITH_LOW_FRAUD_RISK' },
  {
    value: 9,
    label: 'Risco moderado',
    name: 'REJECTED_FOR_MEDIUM_FRAUD_RISK',
  },
  { value: 10, label: 'Risco alto', name: 'REJECTED_FOR_HIGH_FRAUD_RISK' },
  { value: 11, label: 'Negado', name: 'DENIED_BY_CARD_PROVIDER' },
  { value: 12, label: 'Pendente' },
  {
    value: 13,
    label: 'Chargeback Solicitado',
    name: 'CHARGEDBACK',
  },
];

export const referralCodesOrdenations = [{ value: true, label: 'Usos' }];

export const exportTypes = [
  // { value: 'salesFromCurrentMonth', label: 'Vendas finalizadas no mês atual' },
  // {
  //   value: 'salesFromLastQuarter',
  //   label: 'Vendas finalizadas nos últimos 3 meses',
  // },
  // { value: 'salesFromCurrentYear', label: 'Vendas finalizadas no ano' },
  // {
  //   value: 'allSalesFromLastQuarter',
  //   label: 'Todas as vendas nos últimos 3 meses',
  // },
  { value: 'salesByDate', label: 'Vendas' },
  { value: 'chargesByDate', label: 'Pagamentos' },
  { value: 'deliveriesByDate', label: 'Logística' },
  { value: 'users', label: 'Usuários' },
  { value: 'users-clients', label: 'Clientes Fidelizados' },
  { value: 'storesByDate', label: 'Lojas' },
];
