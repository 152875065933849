import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  ModalHeader,
  Modal,
  ModalBody,
  Label,
  Button,
  ModalFooter,
} from 'reactstrap';
// import { Form } from '@unform/web';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';
// import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { useSelector } from 'react-redux';

import { Form } from '@unform/web';
import InputSelect from '~/components/Inputs/InputSelect';
import api from '~/services/api';
// import InputSelect from '~/components/Inputs/InputSelect';
import PageHeader from '~/components/Common/PageHeader';
// import SalesmanRanking from '~/components/SalesmanRanking';
// import DataTableBase from '~/components/DataTable';
import { exportTypes } from '~/util/filters';
import { fileTypes } from '~/util/fileTypes';
import DatePicker from '~/components/DatePicker';
// import DonutCard from './DonutCard';
// import DailyRevenue from './DailyRevenue';
import { lineChart } from '~/pages/Dashboard/ChartOptions';
import Loading from '~/components/Loading';
import MyStoreCard from '../Dashboard/MyStoreCard';
import ListTab from '../Dashboard/ListTab';
import GeneralTab from '../Dashboard/GeneralTab';

function ManagerDashboard() {
  const formExportType = useRef(null);
  const formRef = useRef(null);
  // const toastId = useRef(null);

  const userStores = useSelector(({ user }) =>
    user.profile.managed_stores.map(s => ({
      value: s.id,
      label: s.fantasy_name,
    }))
  );
  const userStore = useSelector(({ user }) => ({
    value: user?.profile?.stock?.id,
    label: user?.profile?.stock?.fantasy_name,
  }));

  const [loading, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState({
    label: '2025',
    value: '2025',
  });
  const [timeNow, setTimeNow] = useState(
    format(new Date(), "dd MMM yy '-' HH'h'mm", {
      locale: ptBR,
    })
  );
  const [tab, setTab] = useState(1);
  // const [statistics, setStatistics] = useState();
  // const [selectedStore, setSelectedStore] = useState();
  const [showExportModal, setShowExportModal] = useState(false);
  // const [seriesDonut, setSeriesDonut] = useState([]);

  // const [salesQuarter, setSalesQuarter] = useState([]);
  // const [salesMonth, setSalesMonth] = useState([]);
  // const [salesYear, setSalesYear] = useState([]);
  // const [amountCurrentMonth, setAmountCurrentMonth] = useState([]);
  // const [amountDay, setAmountDay] = useState([]);
  // const [amountYear, setAmountYear] = useState([]);
  // const [clients, setClients] = useState([]);

  const [selectedStore, setSelectedStore] = useState(
    userStore.value ? userStore : null
  );
  const [deliveryProfileInformation, setDeliveryProfileInformation] =
    useState();
  const [store, setStore] = useState(null);

  const [options, setOptions] = useState(lineChart);

  // const [series, setSeries] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectedExport, setSelectedExport] = useState(null);

  const [optionsReport, setOptionsReport] = useState({
    export: true,
    file_type: 'xls',
    // dateFinish: format(new Date(), 'yyyy-MM-dd', { locale: ptBR }),
  });

  // const userStore = useSelector(({ user }) => user?.profile?.stock);

  // useEffect(() => {
  //   setSelectedStore({
  //     value: userStore?.id,
  //     label: userStore?.fantasy_name,
  //   });
  // }, [userStore]);

  // const hasReferralRanking = useMemo(
  //   () => !!statistics?.referral_codes_ranking,
  //   [statistics]
  // );

  // const hasRanking = useMemo(
  //   () => !!statistics?.salesmans_ranking,
  //   [statistics]
  // );

  // const salesmansRankingLength = useMemo(
  //   () => hasRanking && statistics?.salesmans_ranking.length,
  //   [hasRanking, statistics]
  // );

  // const columns = [
  //   {
  //     id: 1,
  //     name: 'Data',
  //     selector: row => row.data,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 2,
  //     name: 'Cidade',
  //     selector: row => row.city,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 3,
  //     name: 'Loja',
  //     selector: row => row.store,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 4,
  //     name: 'Pedido',
  //     selector: row => row.order_number,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 5,
  //     name: 'Vendedor(a)',
  //     selector: row => row.seller,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 6,
  //     name: 'Cliente',
  //     selector: row => row.client,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 7,
  //     name: 'CPF Cliente',
  //     selector: row => row.client_cpf,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 8,
  //     name: 'Valor Final (R$)',
  //     selector: row => row.final_value,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 9,
  //     name: 'Número da Nota',
  //     selector: row => row.nfce_number,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 10,
  //     name: 'Serviço de Entrega',
  //     selector: row => row.services_type,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 11,
  //     name: 'ID da Entrega',
  //     selector: row => row.delivery_id,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 12,
  //     name: 'ID do Pagamento',
  //     selector: row => row.transaction_id,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   {
  //     id: 13,
  //     name: 'Tipo de Pagamento',
  //     selector: row => row.payment_type,
  //     sortable: true,
  //     reorder: true,
  //   },
  //   lector: row => row.flow,
  //     sor{
  //     id: 14,
  //     name: 'Fluxo Utilizado',
  //     setable: true,
  //     reorder: true,
  //   },
  // ];

  const pendingSalesAmount = useCallback(
    data =>
      data?.carts_by_status[0] +
      data?.carts_by_status[1] +
      data?.carts_by_status[2] +
      data?.carts_by_status[3],
    []
  );

  // const dataStore = useCallback(async salesData => {
  //   const days = Object.keys(salesData.amount_history);
  //   // const seriesData = Object.values(salesData.amount_history);

  //   setOptions(prevOptions => ({
  //     ...prevOptions,
  //     xaxis: { ...prevOptions.xaxis, days },
  //   }));

  //   // setSeries([{ name: 'Valor', data: seriesData }]);
  //   // setSeriesDonut([
  //   //   salesData.carts_by_status[4],
  //   //   salesData.carts_with_referral_code_count,
  //   //   pendingSalesAmount(salesData),
  //   //   salesData.carts_by_status[6],
  //   // ]);
  //   // setStatistics(salesData);
  //   setTimeNow(new Date().toISOString());
  //   setTimeNow(
  //     format(new Date(), "dd MMM yy '-' HH'h'mm", {
  //       locale: ptBR,
  //     })
  //   );
  //   setLoading(false);
  // }, []);

  const loadProfile = async () => {
    if (selectedStore?.value) {
      try {
        const { data: deliveryProfileData } = await api.get(
          `store-manager/stores/${
            selectedStore?.value || ''
          }/delivery/profile-data`
        );
        setDeliveryProfileInformation(deliveryProfileData);
      } catch (err) {
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      }
    }
  };

  const loadStore = async () => {
    try {
      //   let params = {
      //     storeId: selectedStore?.value || '',
      //     dateMonth: format(new Date(), 'yyyy-MM', {
      //       locale: ptBR,
      //     }),
      //   };

      //   if (!selectedStore?.value) {
      //     params = {
      //       dateMonth: format(new Date(), 'yyyy-MM', {
      //         locale: ptBR,
      //       }),
      //     };
      //   }

      const [
        { data: storeFromData },
        // { data },
        // { data: amountFromCurrentMonth },
        // { data: amountFromCurrentDay },
        // { data: amountFromCurrentYear },
        // { data: totalClients },
      ] = await Promise.all([
        api.get(`stores/${selectedStore?.value || ''}`),
        // api.get(`store-manager/pages`, {
        //   params,
        // }),
        // api.get(
        //   `administrator/pages/dashboard/amountFromCurrentMonth/${
        //     selectedStore?.value || ''
        //   }`
        // ),
        // api.get(
        //   `administrator/pages/dashboard/amountFromCurrentDay/${
        //     selectedStore?.value || ''
        //   }`
        // ),
        // api.get(
        //   `administrator/pages/dashboard/amountFromCurrentYear/${
        //     selectedStore?.value || ''
        //   }`
        // ),
        // api.get(`administrator/pages/dashboard/totalClients`),
      ]);

      setStore(storeFromData?.store);

      // setAmountCurrentMonth(amountFromCurrentMonth?.amount);
      // setAmountDay(amountFromCurrentDay?.amount);
      // setAmountYear(amountFromCurrentYear?.amount);
      // setClients(totalClients?.total_clients);

      // const days = Object.keys(data.amount_history);
      // const seriesData = Object.values(data.amount_history);

      const date = new Date();
      const day = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();

      setOptions({
        ...options,
        xaxis: {
          ...options.xaxis,
          days: Array.from(new Array(day), (_, i) => i),
        },
      }); // days

      // setSeries([{ name: 'Valor', data: seriesData }]); // seriesData
      // if (data) {
      //   setStatistics(data);
      // }

      // dataStore(data);

      setTimeNow(
        format(new Date(), "dd MMM yy '-' HH'h'mm", {
          locale: ptBR,
        })
      );

      setLoading(false);
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
      setLoading(false);
    }
  };

  const handleGenerateReport = async () => {
    try {
      if (!selectedExport) {
        formExportType.current.setFieldError(
          'export-type',
          'É obrigatório o tipo de relatório.'
        );
        return;
      }
      toast.info(
        'Estamos gerando seu relatório, e ele será enviado para o seu e-mail.'
      );

      await api.get(`store-manager/pages/${selectedExport.value}`, {
        params: optionsReport,
        responseType: 'blob',
      });

      setStartDate(null);
      setEndDate(null);

      setOptionsReport({
        export: true,
        file_type: 'xls',
        // dateFinish: format(new Date(), 'yyyy-MM-dd', { locale: ptBR }),
      });

      setShowExportModal(false);
      toast.success('Relatório gerado, já deve constar no seu e-mail.');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      } else {
        toast.error('Erro ao gerar relatório');
      }
    }
  };

  // const handleExportCSV = async () => {
  //   try {
  //     toastId.current = toast.info(
  //       'Estamos gerando seu relatório, e ele será enviado para o seu e-mail.',
  //       {
  //         autoClose: 5000,
  //       }
  //     );

  //     api.get(`administrator/pages/dashboard/${selectedExport.value}`, {
  //       params: optionsReport,
  //       responseType: 'blob',
  //     });
  //   } catch (err) {
  //     console.log(err);
  //     toast.update(toastId.current, {
  //       render: 'Houve um erro ao exportar os dados',
  //       type: toast.TYPE.ERROR,
  //       autoClose: 5000,
  //       transition: Flip,
  //     });
  //   }
  // };

  useEffect(() => {
    setLoading(true);
    loadStore();
    loadProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingSalesAmount, selectedStore?.value, dateFilter.value]);

  if (loading)
    return (
      <div className="w-100 d-flex justify-content-center">
        <Loading />
      </div>
    );

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <Row className="w-100 mx-0 align-items-center">
          <Col lg={3} className="px-0 mb-4 mb-lg-0">
            <PageHeader
              pageTitle="DASHBOARD"
              responsivePosition="start"
              desktopPosition="start"
              subtitle={`Dados coletados em ${timeNow}`}
            />
          </Col>
          <Col lg={7} className="px-0 mb-4 mb-lg-0">
            <Form
              ref={formRef}
              onSubmit={data =>
                // applyFilterOnDashboard(data.store_id ? data : null)
                data
              }
            >
              <Row className="w-100 mx-0 my-4 my-lg-0 justify-content-end">
                <Col lg={4} className="col-lg-auto px-0 pr-lg-2 mb-2 mb-lg-0">
                  <InputSelect
                    name="store_id"
                    id="store_id"
                    options={userStores}
                    value={selectedStore || null}
                    placeholder="Todas as lojas"
                    onChange={setSelectedStore}
                    isClearable
                  />
                </Col>
                <Col lg={4} className="col-lg-auto px-0 pr-lg-2 mb-2 mb-lg-0">
                  <InputSelect
                    name="date_filter"
                    id="date_filter"
                    options={[
                      '2025',
                      '2024',
                      '2023',
                      '2022',
                      '2021',
                      '2020',
                      '2019',
                      '2018',
                      '2017',
                    ].map(year => ({ label: year, value: year }))}
                    value={dateFilter}
                    onChange={option => {
                      setDateFilter({
                        label: option.label,
                        value: option.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          <Col className="px-0 pl-lg-2" lg={2}>
            <Button
              id="export-data-dashboard-btn"
              color="primary"
              onClick={() => setShowExportModal(true)}
              className="new-layout-btn main d-flex flex-row justify-content-center align-items-center my-4 w-100"
              style={{
                height: '46px',
              }}
            >
              <div className="ml-2">Exportar Dados</div>
            </Button>
          </Col>
        </Row>

        <Row className="gap-3 mx-0 font-size-14 font-weight-500 text-black">
          <Col
            className={`px-3 py-2 dashboard-card-border rounded-0 ${
              tab === 1 ? 'btn-primary' : 'bg-white'
            }`}
            onClick={() => setTab(1)}
            style={{
              cursor: 'pointer',
              maxWidth: 'fit-content',
            }}
          >
            Geral
          </Col>
          <Col
            className={`px-3 py-2 dashboard-card-border rounded-0 ${
              tab === 2 ? 'btn-primary' : 'bg-white'
            }`}
            onClick={() => setTab(2)}
            style={{
              cursor: 'pointer',
              maxWidth: 'fit-content',
            }}
          >
            Listas
          </Col>
        </Row>
        <Row className="p-3 mx-0 dashboard-card-border">
          {tab === 1 && (
            <GeneralTab
              store={store}
              selectedStore={selectedStore}
              startDateParameter={`${dateFilter.value}-01-01`}
              endDateParameter={`${dateFilter.value}-12-31`}
            >
              {store && (
                <Row className="w-100 mx-0">
                  <MyStoreCard
                    myStore={store}
                    balanceInformation={deliveryProfileInformation}
                  />
                </Row>
              )}
            </GeneralTab>
          )}
          {tab === 2 && (
            <ListTab
              selectedStore={selectedStore}
              startDateParameter={`${dateFilter.value}-01-01`}
              endDateParameter={`${dateFilter.value}-12-31`}
            />
          )}
        </Row>
      </Container>
      <Modal isOpen={showExportModal}>
        <ModalHeader toggle={() => setShowExportModal(false)}>
          Exportar Dados
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Tipo de relatório</Label>

              <Form ref={formExportType}>
                <InputSelect
                  name="export-type"
                  options={exportTypes}
                  onChange={option => {
                    setSelectedExport(option);
                  }}
                  isClearable
                  value={selectedExport}
                  placeholder="Selecione o tipo desejado"
                  singlePath
                />
              </Form>
            </Col>
          </Row>
          <Row className="w-100 mx-0 my-2">
            <Col className="px-0">
              <Label>Deseja filtrar por uma loja específica?</Label>
              <ReactSelect
                name="store-manager-report"
                id="store-manager-report"
                options={userStores}
                classNamePrefix="select"
                placeholder="Todos as lojas"
                isClearable
                value={userStores.find(s => s.value === optionsReport.storeId)}
                onChange={option => {
                  setOptionsReport({
                    ...optionsReport,
                    storeId: option?.value,
                  });
                }}
                noOptionsMessage={() =>
                  'Não há lojas cadastradas para o seu usuário'
                }
              />
            </Col>
          </Row>
          {selectedExport?.value !== 'users' && (
            <Row className="w-100 mx-0 my-2">
              <Col className="px-0">
                <Label>
                  Deseja filtrar por um intervalo de datas específico?
                </Label>
                <Row className="w-100 mx-0 mb-2 mb-lg-2 d-flex flex-row align-items-center">
                  <Col xs="6" className="pl-0 pr-2 mb-2 mb-lg-0">
                    <DatePicker
                      value={startDate}
                      onChange={selectedDate => {
                        const date = format(selectedDate, 'yyyy-MM-dd', {
                          locale: ptBR,
                        });
                        setOptionsReport({
                          ...optionsReport,
                          dateStart: date,
                        });
                        setStartDate(selectedDate);
                      }}
                      mask="__/__/___"
                      placeholder="Dia Inicial"
                    />
                  </Col>
                  <Col xs="6" className="pl-0 pr-2 mb-2 mb-lg-0">
                    <DatePicker
                      value={endDate}
                      onChange={selectedDate => {
                        const date = format(selectedDate, 'yyyy-MM-dd', {
                          locale: ptBR,
                        });
                        setOptionsReport({
                          ...optionsReport,
                          dateFinish: date,
                        });
                        setEndDate(selectedDate);
                      }}
                      mask="__/__/___"
                      placeholder="Dia Final"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="w-100 mx-0 mb-4 mt-2">
            <Col className="px-0">
              <Label>Qual formato de arquivo que deseja no relatório?</Label>
              <ReactSelect
                name="filetype-financier-report"
                id="filetype-financier-report"
                options={fileTypes}
                classNamePrefix="select"
                placeholder="Formato de arquivo"
                isClearable
                value={fileTypes.find(
                  p => p?.value === optionsReport?.file_type
                )}
                onChange={option => {
                  setOptionsReport(state => ({
                    ...state,
                    file_type: option?.value,
                  }));
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            color="primary"
            className="new-layout-btn main"
            onClick={handleGenerateReport}
            // disabled={!selectedExport}
          >
            Exportar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ManagerDashboard;
