import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  CardBody,
  Card,
  Collapse,
  // UncontrolledTooltip,
  // UncontrolledTooltip,
  // CustomInput,
} from 'reactstrap';
import { FaRegStar, FaStar } from 'react-icons/fa';

import { Flip, toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';
// import { format } from 'date-fns';
import Input from '~/components/Inputs/Input';

import api from '~/services/api';

import UnifyDeliveriesModal from '~/components/Modals/UnifyDeliveriesModal';
// import { setStock, setTemporaryStore } from '~/store/modules/cart/actions';

import PageHeader from '~/components/Common/PageHeader';
import CartMainInfoCard from '~/components/CartDetailsComponents/Cards/CartMainInfoCard';
import ProductsListCard from '~/components/CartDetailsComponents/Cards/ProductsListCard';
import FinalValueCard from '~/components/CartDetailsComponents/Cards/FinalValueCard';
import CartManagementButtons from '~/components/CartDetailsComponents/CartManagementButtons';
import ConsultantManagementButtons from '~/components/CartDetailsComponents/ConsultantManagementButtons';
import {
  formatCustomer,
  formatPayment,
  formatProducts,
  formatCart,
} from '~/objectFormatters/Sale';
import SaleStatusBadge from '~/components/SaleStatusBadge';
import { LG } from '~/util/breakpointWidths';
import Loading from '~/components/Loading';
import { getRole } from '~/util/getRole';
import useRoles from '~/hooks/useRoles';
// import GiftBoxesCard from '~/components/CartDetailsComponents/Cards/GiftBoxesCard';
// import TimePicker from '~/components/TimePicker';
// import DatePicker from '~/components/DatePicker';
// import TaxCuponsCard from '~/components/CartDetailsComponents/Cards/TaxCuponsCard';
import CuponsNumberCard from '~/components/CartDetailsComponents/Cards/CuponsNumberCard';
import RatingDeliveryModal from '~/components/Modals/RatingModal';
import { capitalize, formatWhatsappPhoneNumber } from '~/util/format';
import {
  ARRIVED_AT_CLIENT,
  ARRIVED_AT_STORE,
  CANCELLED,
  DELIVERED,
  GOING_TO_CLIENT,
  GOING_TO_STORE,
  SEARCHING,
} from '~/util/deliveryStatuses';
import CustomerDataCard from '~/components/CartDetailsComponents/Cards/CustomerDataCard';
import MobileHeader from '~/components/HorizontalLayout/Header/MobileHeader';
import PaymentCard from '~/components/CartDetailsComponents/Cards/PaymentCard';
import IconButton from '~/components/IconButton';
import { setAsRescheduled } from '~/store/modules/sales/actions';
// import { setAsRescheduled } from '~/store/modules/sales/actions';
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { SaleMessageBadge } from '~/components/SaleStatusBadge/SaleMessageBadge';
import InputSelect from '~/components/Inputs/InputSelect';
import DeliveryQrcode from './DeliveryQrcode';
import ColectDeliveryForm from './ColectDeliveryForm';
import PaymentSuitcaseForm from './PaymentSuitcaseForm';
import {
  DeliveryInfoCard,
  ColectInfoCard,
  PaymentInfoCard,
  BillingInfoCard,
  defineDeliveryStatusColor,
} from './DeliveryInfoCard';

const TimerDisplay = ({ timestamp }) => {
  const datetime = new Date(timestamp);

  const seconds = String(60 - datetime.getSeconds()).padStart(2, '0');
  const minutes = String(2 - datetime.getMinutes()).padStart(2, '0');

  return (
    <span
      className="text-secondary font-size-10"
      style={{ position: 'absolute', right: '0.5rem' }}
    >
      <i className="far fa-clock" style={{ marginRight: '1.5rem' }} />
      <span>{`${minutes}:${seconds}`}</span>
    </span>
  );
};

const Sale = ({ match }) => {
  const toastID = useRef(null);

  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'cross-manager',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const { isConsultant } = useRoles();

  const subscriberId = localStorage.getItem('@VIDDY:X-Server');
  const subscriberViddyComercial = '504c2f33-f3af-411c-b6f4-c9c483f8b745';

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const formRef = useRef(null);

  const cuponsformRef = useRef(null);

  const [sale, setSale] = useState(null);
  const [products, setProducts] = useState(null);
  const [fee, setFee] = useState(null);

  const [client, setClient] = useState(null);
  const [unifiableCarts, setUnifiableCarts] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [cartsToUnify, setCartsToUnify] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingInformation, setLoadingInformation] = useState(true);
  const [showUnifyDeliveriesModal, setShowUnifyDeliveriesModal] =
    useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [isCancelDeliveryModalOpen, setIsCancelDeliveryModalOpen] =
    useState(false);
  const [isFinishCancelledOrderModalOpen, setIsFinishCancelledOrderModalOpen] =
    useState(false);
  // const [isRetryDeliveryModalOpen, setIsRetryDeliveryModalOpen] =
  //   useState(false);

  // const [deliveryNow, setDeliveryNow] = useState(true);
  // const [scheduledDeliveryDate, setScheduledDeliveryDate] = useState(null);
  // const [scheduledDeliveryTime, setScheduledDeliveryTime] = useState(null);

  const [delivery, setDelivery] = useState(null);
  const [shouldReloadCart, setShouldReloadCart] = useState(true);
  // const [prevStatus, setPrevStatus] = useState(null);

  const [shareableURL, setShareableURL] = useState(null);
  const [shouldShowColectDeliveryForm, setShouldShowColectDeliveryForm] =
    useState(null);
  const [shouldShowPaymentSuitcaseform, setShouldShowPaymentSuitcaseform] =
    useState(null);
  // const [shareablePix, setShareablePix] = useState(null);

  const params = location.state?.params;

  const { id } = match.params;

  const windowSize = useSelector(({ layout }) => layout.windowSize);
  const showSidebar = useSelector(({ layout }) => layout.windowSize);

  const [statusDataCardExpand, setStatusDataCardExpand] = useState(true);

  const [finalizationReason, setFinalizationReason] = useState(null);

  const showButtons = useMemo(() => {
    if (windowSize < LG) {
      return true;
    }
    return false;
  }, [windowSize]);

  const hideButtonSaleCross = useMemo(
    () =>
      (sale?.status >= 2 && sale?.is_cross && role === 'digital-consultant') ||
      (sale?.is_cross &&
        sale?.status_text === 'Aguardando aceite' &&
        role === 'cross-manager'),
    [sale, role]
  );

  /* const showGiftBoxesCard = useMemo(
    () => products?.some(p => p.pivot.gift),
    [products]
  ); */

  const setData = cart => {
    const formattedSale = formatCart(cart);
    setSale(formattedSale);
  };

  const setProductsData = productsArr => {
    const formattedProducts = formatProducts(productsArr);
    setProducts(formattedProducts);
  };

  const loadSale = useCallback(async () => {
    const loadDelivery = async () => {
      try {
        const { data } = await api.get(`${role}/carts/${id}/delivery`);

        if (data) {
          setDelivery(data.delivery);
          // setPrevStatus(data.delivery?.status);
        }
      } catch (err) {
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        }
      }
    };

    const {
      data: { cart },
    } = await api.get(`${role}/carts/${id}`);

    if (cart) {
      if (
        !(cart?.status === 0 && cart.is_shareable) ||
        cart.is_payment_checkout
      ) {
        if (
          cart?.status >= 3 &&
          cart.is_for_delivery &&
          ['BEE', 'MOTTU', 'UBER', 'CORREIOS', 'LALAMOVE', 'PROPRIA'].includes(
            cart.delivery_method
          )
        ) {
          await loadDelivery();
        }

        if (
          ['BEE', 'MOTTU', 'UBER', 'CORREIOS', 'LALAMOVE', 'PROPRIA'].includes(
            cart.delivery_method
          )
        ) {
          const {
            data: { alerts: alertsList },
          } = await api.get(`${role}/carts/${id}/alerts`);

          setAlerts(alertsList);
          const unifiableAlert = alertsList.filter(
            a => a.type === 'CARTS_WITH_CLOSE_DESTINATION'
          );

          const unifiableCartsList = unifiableAlert[0]?.carts.map(c => ({
            ...c,
            checked: false,
          }));

          setUnifiableCarts(unifiableCartsList);
        }

        // if (cart?.status !== 6 && cart?.status >= 1 && cart.is_for_delivery) {
        //   const { data } = await api.get(
        //     `${
        //       isStoreManager ? 'store-manager' : 'salesman'
        //     }/carts/${id}/delivery/freight`
        //   );

        //   setFreight(data.freight);
        // }

        if (cart?.status > 0 && cart.payment_process) {
          const { data } = await api.get(`${role}/carts/${id}/payment`);

          const formattedFee = formatPayment(data);
          setFee(formattedFee);
        }

        if (cart.client) {
          try {
            const {
              data: { client: customer },
            } = await api.get(`clients/${cart?.client?.id}`);

            const formattedCustomer = formatCustomer(customer);

            setClient(formattedCustomer);
          } catch (err) {
            if (err.response && err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error('Ocorreu um erro ao carregar o cliente');
            }
          }
        }
      }
      setProductsData(cart.products);
      setData(cart);

      if (cart.is_shareable && cart?.status !== 6) {
        setShareableURL(cart?.url_shortener?.url);
      }
      setShouldReloadCart(false);
    }
  }, [role, id]);

  useEffect(() => {
    if (!shouldReloadCart) return;

    document.title = 'Venda | Viddy';

    setLoading(true);
    loadSale()
      .then(() => setLoading(false))
      .catch(e => {
        console.error(e);
        setLoading(false);
        toast.error('Não foi possível carregar as informações dessa venda.');
        history.push({
          pathname: '/',
          state: { ...params },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSale, shouldReloadCart]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (sale?.status === 4) return;
      if (sale?.status === 6) return;
      if (sale?.status === 9) return;
      if (sale?.status === 12) return;
      loadSale();
    }, 40 * 1000);

    return () => clearInterval(refreshInterval);
  }, [sale, loadSale]);

  useEffect(() => {
    if (
      sale?.is_for_delivery &&
      (sale?.delivery?.status === 'DELIVERED' ||
        sale?.delivery?.status === 'COMPLETED') &&
      !sale?.delivery_score &&
      !sale.return_delivery
    ) {
      setShowRatingModal(true);
    }

    if (
      sale?.is_for_delivery &&
      (sale?.return_delivery?.status === 'DELIVERED' ||
        sale?.return_delivery?.status === 'COMPLETED') &&
      !sale?.return_delivery_score
    ) {
      setShowRatingModal(true);
    }
  }, [sale]);

  // useEffect(() => {
  //   let interval;

  //   if (
  //     sale &&
  //     sale.is_for_delivery &&
  //     sale?.status >= 3 &&
  //     sale?.status !== 4 &&
  //     sale?.status !== 6 &&
  //     sale.delivery_method === 'BEE' &&
  //     sale.delivery_address &&
  //     sale.delivery?.status !== 'DELIVERED' &&
  //     sale.delivery?.status !== 'COMPLETED' &&
  //     sale.delivery?.status !== 'CANCELED'
  //   )
  //     interval = setInterval(async () => {
  //       const { data } = await api.get(`${role}/carts/${id}/delivery`);
  //       setDelivery(data.delivery);
  //       setPrevStatus(data.delivery?.status);
  //       if (prevStatus !== data.delivery?.status) {
  //         toast.success('Status de entrega atualizada!', { autoClose: 5000 });
  //       }
  //     }, 5 * 1000);

  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [prevStatus, id, sale]);

  const handleFinishCancelledOrder = async data => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        finalization_details: Yup.string(),
        finalization_reason: Yup.string().required(
          'Insira o método da entrega'
        ),
      });

      await schema.validate(
        {
          ...data,
          finalization_reason: finalizationReason,
        },
        {
          abortEarly: false,
        }
      );

      const {
        data: { cart },
      } = await api.patch(`${role}/carts/${id}/finalize`, data);

      setData(cart);
      setIsFinishCancelledOrderModalOpen(false);
      setShouldReloadCart(true);
      toast.success('Venda manualmente marcada como entregue com sucesso!');
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  const handleAddToUnifiedCarts = cartId => {
    const alreadyAdded = unifiableCarts.find(cart => cart === cartId);
    if (alreadyAdded) return;

    const draft = unifiableCarts.map(cart =>
      cart.id === cartId ? { ...cart, checked: true } : cart
    );

    setUnifiableCarts(draft);

    setCartsToUnify(
      draft.filter(cart => {
        return cart.checked;
      })
    );
  };

  const handleRemoveFromUnifiedCarts = cartId => {
    const draft = unifiableCarts.map(cart =>
      cart.id === cartId ? { ...cart, checked: false } : cart
    );

    setUnifiableCarts(draft);

    setCartsToUnify(
      draft.filter(cart => {
        return cart.checked;
      })
    );
  };

  const handleUnifyDeliveries = async () => {
    const parentCard = [{ id, order_number: sale.order_number, checked: true }];

    const unifiableCartsList = parentCard
      .concat(unifiableCarts)
      .filter(c => c.checked === true)
      .map(c => c.id);

    if (unifiableCartsList.length === 1) {
      toast.error(
        'Para unificar entregas, se faz necessário dois ou mais carrinhos, incluindo o atual.'
      );
    } else {
      try {
        toastID.current = toast.info('Registrando chamada...', {
          autoClose: false,
        });

        await api.post(`${role}/carts/request-unified-delivery`, {
          carts: unifiableCartsList,
        });

        toast.update(toastID.current, {
          render:
            'Chamada registrada com sucesso para todos os carrinhos! Redirecionando...',
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
          transition: Flip,
        });

        history.push('/');
      } catch (err) {
        toast.update(toastID.current, {
          render: err?.response?.data?.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          transition: Flip,
        });
      }
    }
  };

  const handleAddCuponsNumber = async data => {
    try {
      const { receipt_number } = data;

      cuponsformRef.current.setErrors({});

      const schema = Yup.object().shape({
        receipt_number: Yup.string().required(
          'O número da nota fiscal não pode estar vazio!'
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!receipt_number) {
        toast.error('O número da nota não pode estar vazio!');
        return;
      }

      setLoading(true);

      await api.patch(`${role}/carts/${id}`, {
        nfce: {
          number: receipt_number,
        },
      });

      const {
        data: { cart },
      } = await api.get(`${role}/carts/${id}`);

      setData(cart);

      setLoading(false);

      toast.success('Número da nota fiscal salvo com sucesso!');
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        cuponsformRef.current.setErrors(validationErrors);
      }

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  // const handleUploadTaxCoupon = async data => {
  //   try {
  //     if (data !== null) {
  //       setLoading(true);

  //       const formData = new FormData();

  //       formData.append('tax_coupon', data);

  //       await api.post(`${role}/carts/${id}/attach-tax-coupon`, formData);

  //       const {
  //         data: { cart },
  //       } = await api.get(`${role}/carts/${id}`);

  //       setData(cart);

  //       setLoading(false);

  //       toast.success('Cupom fiscal anexado');
  //     } else {
  //       setLoading(true);

  //       await api.post(`${role}/carts/${id}/attach-tax-coupon`, {
  //         tax_coupon: null,
  //       });

  //       const {
  //         data: { cart },
  //       } = await api.get(`${role}/carts/${id}`);

  //       setData(cart);

  //       setLoading(false);

  //       toast.success('Cupom fiscal removido');
  //     }
  //   } catch (err) {
  //     if (err.response && err?.response?.data?.message)
  //       toast.error(err?.response?.data?.message);
  //   }
  // };

  // const handleRetryDelivery = async () => {
  //   try {
  //     setLoading(true);

  //     const date = format(scheduledDeliveryDate, 'dd MMMM yyyy');
  //     const time = format(scheduledDeliveryTime, "HH':'mm'");

  //     const deliveryMoment = new Date(`${date} ${time}`);

  //     const {
  //       data: { delivery: saleRetryDelivery },
  //     } = await api.post(`${role}/carts/${sale.id}/delivery/retry`, {
  //       scheduled_to: deliveryMoment,
  //       force_retry: forceRetry,
  //     });

  //     const {
  //       data: { cart },
  //     } = await api.get(`${role}/carts/${sale.id}`);

  //     setData(cart);

  //     setDelivery(saleRetryDelivery);

  //     setIsRetryDeliveryModalOpen(false);

  //     dispatch(setAsRescheduled());

  //     setLoading(false);

  //     toast.success('Nova tentativa de entrega processada!');
  //   } catch (err) {
  //     setLoading(false);
  //     if (err?.response?.data?.scheduled_to[0])
  //       toast.error(
  //         'O campo de agendar entrega deve ser uma data posterior ou igual a agora'
  //       );
  //     toast.error(err?.response?.data?.message);
  //     if (
  //       err?.response?.data?.message ===
  //       'Não é possível solicitar uma nova tentativa de entrega neste momento. Um entregador já pode estar a caminho.'
  //     ) {
  //       setForceRetry(true);
  //     }
  //   }
  // };

  const [forceRetry, setForceRetry] = useState(false);
  const [dateNow, setDateNow] = useState(0);
  const [lastDeliveryForceRetry, setLastDeliveryForceRetry] = useLocalStorage(
    'last_delivery_force_retry',
    0
  );
  const [shouldDisableDeliveryForceRetry, setShouldDisableDeliveryForceRetry] =
    useState(false);

  useEffect(() => {
    if (Date.now() > lastDeliveryForceRetry + 180000) return null;

    const checkLastDeliveryForceRetry = setInterval(() => {
      setDateNow(Date.now());
      setShouldDisableDeliveryForceRetry(
        Date.now() < lastDeliveryForceRetry + 180000
      );
    }, 1000);

    return () => clearInterval(checkLastDeliveryForceRetry);
  }, [lastDeliveryForceRetry]);

  const handleRetryDeliveryNow = async () => {
    try {
      if (shouldDisableDeliveryForceRetry) return;

      setLoading(true);
      setLastDeliveryForceRetry(Date.now());

      // const now = new Date();
      // now.setMinutes(now.getMinutes() + 1);
      // const nowToISO = now.toISOString();

      const {
        data: { delivery: saleRetryDelivery },
      } = await api.post(`${role}/carts/${id}/delivery/retry-force`, {
        force_retry: forceRetry,
      });

      const {
        data: { cart },
      } = await api.get(`${role}/carts/${id}`);

      setData(cart);

      // const {
      //   data: { delivery: saleRetryDelivery },
      // } = await api.post(`${role}/carts/${id}/delivery/retry`, {
      //   scheduled_to: nowToISO,
      // });

      setDelivery(saleRetryDelivery);

      // setIsRetryDeliveryModalOpen(false);

      dispatch(setAsRescheduled());

      setLoading(false);

      toast.success('Nova tentativa de entrega processada!');
    } catch (err) {
      setLoading(false);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
      setForceRetry(true);
    }
  };

  /*
  const handleResetDelivery = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.post(`${role}/carts/${id}/delivery/reset`);

      const saleDraft = {
        ...sale,
        delivery: data.delivery,
      };

      setData(saleDraft);

      setDelivery(data.delivery);

      setLoading(false);
      toast.success('Entrega reiniciada, buscando novo entregador!');
    } catch (err) {
      setLoading(false);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sale]);
  */

  const handleCancelDelivery = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          reason: Yup.string().required('Insira o motivo do cancelamento'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        const {
          data: { delivery: saleDelivery },
        } = await api.patch(`${role}/carts/${id}/delivery/cancel`, data);

        const saleDraft = {
          ...sale,
          delivery: saleDelivery,
        };

        setData(saleDraft);

        setDelivery(saleDelivery);

        setLoading(false);

        setIsCancelDeliveryModalOpen(false);

        setShouldReloadCart(true);
        toast.success('Entrega cancelada com sucesso!');
      } catch (err) {
        const validationErrors = {};

        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(e => {
            validationErrors[e.path] = e.message;
          });
          formRef.current.setErrors(validationErrors);
        }
        if (err.response && err?.response?.data?.message)
          toast.error(err?.response?.data?.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, sale]
  );

  const handleAcceptOrder = async () => {
    try {
      setLoading(true);

      await api.patch(`${role}/carts/${id}/cross/accept`);

      setLoading(false);
      history.push('/');
      toast.success('Pedido aceito!');
    } catch (err) {
      setLoading(false);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  const toggle = () => setShowUnifyDeliveriesModal(!showUnifyDeliveriesModal);

  const toggleCancelDelivery = () =>
    setIsCancelDeliveryModalOpen(!isCancelDeliveryModalOpen);

  const toggleFinishCancelledOrder = () =>
    setIsFinishCancelledOrderModalOpen(!isFinishCancelledOrderModalOpen);

  const toggleRating = () => setShowRatingModal(!showRatingModal);

  // const toggleRetryDelivery = () =>
  //   setIsRetryDeliveryModalOpen(!isRetryDeliveryModalOpen);

  const alertsLength = useMemo(() => alerts.length, [alerts]);

  useEffect(() => {
    if (loadingInformation && sale && fee && client) {
      setLoadingInformation(false);
    }
  }, [fee, sale, client, loadingInformation]);

  useEffect(() => {
    return () => {
      if (isConsultant) {
        dispatch(setSale(null));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatPhone = phone => {
    if (!/^\d+$/.test(phone)) return phone;
    const ddd = phone.slice(0, 2);
    let first;
    let last;
    if (phone[2] === '9' && phone.length === 11) {
      first = phone.slice(2, 7);
      last = phone.slice(7, 11);
    } else {
      first = phone.slice(2, 6);
      last = phone.slice(6, 10);
    }
    return `(${ddd}) ${first}-${last}`;
  };

  const salePaymentReverse = useCallback(async () => {
    try {
      setLoading(true);

      await api.patch(`/${role}/carts/${sale?.id}/reverse`);

      setLoading(false);
      setShouldReloadCart(true);
      toast.success('Solicitação de estorno enviada!');
    } catch (err) {
      setLoading(false);
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  }, [sale?.id, role]);

  const currentHours = new Date().getHours();

  const shouldShowDeliveryAdvices =
    !loading &&
    sale?.is_for_delivery &&
    (!sale?.delivery_method === 'CORREIOS' ||
      !sale?.delivery_method === 'LALAMOVE') &&
    sale?.status !== 0 &&
    ((!sale?.delivery &&
      (sale?.payment_process?.status === 'Paid' ||
        sale?.is_suitcase_delivery)) ||
      (sale?.delivery &&
        !sale?.return_delivery &&
        sale?.delivery?.status_text !== 'A caminho do cliente' &&
        sale?.delivery?.status !== 'ARRIVED_AT_DESTINATION' &&
        sale?.delivery?.status !== 'DELIVERED' &&
        sale?.delivery?.status !== 'CANCELED') ||
      (sale?.return_delivery &&
        sale?.return_delivery?.status_text !== 'A caminho da loja' &&
        sale?.return_delivery?.status !== 'ARRIVED_AT_STORE' &&
        sale?.return_delivery?.status !== 'DELIVERED' &&
        sale?.return_delivery?.status !== 'CANCELED') ||
      sale?.status_text === 'Aguardando estoquista');

  const waitTillTomorrowAdvice =
    shouldShowDeliveryAdvices && currentHours >= 19;
  const waitTillCommercialAdvice =
    shouldShowDeliveryAdvices && currentHours <= 8;
  const saleValueAttentionAdvice =
    shouldShowDeliveryAdvices && (Number(sale?.amount) || 0) >= 1500;

  if (shouldShowColectDeliveryForm)
    return (
      <ColectDeliveryForm
        setShouldReloadCart={setShouldReloadCart}
        sale={sale}
        setShouldShowColectDeliveryForm={setShouldShowColectDeliveryForm}
        role={role}
        client={client}
      />
    );

  if (shouldShowPaymentSuitcaseform)
    return (
      <PaymentSuitcaseForm
        sale={sale}
        setShouldShowPaymentSuitcaseform={setShouldShowPaymentSuitcaseform}
        setShouldReloadCart={setShouldReloadCart}
      />
    );

  return (
    <>
      <MobileHeader
        saleDetails
        arrowAction={() => history.push('/')}
        pageTitle="Detalhes do pedido"
      />
      <div className="page-content">
        <Container className="container new-layout wide">
          {!loading && !shouldReloadCart && sale ? (
            <SaleStatusBadge cart={sale} cartDetails mobile />
          ) : null}
          <Row className="d-none d-lg-flex w-100 mx-0">
            <Col xs={3} className="px-0">
              <PageHeader
                pageTitle="Detalhes do pedido"
                responsivePosition="start"
                desktopPosition="start"
                backTo={role === 'financier' ? '/financeiro' : '/'}
                params={params}
              />
            </Col>
            {/* <Col xs={2} className="px-0" align="end">
            {sale?.delivery_method === 'BEE' &&
              sale?.status === 2 &&
              alertsLength !== 0 && (
                <>
                  <UncontrolledTooltip placement="top" target="alertIcon">
                    Alertas
                  </UncontrolledTooltip>
                  <i
                    id="alertIcon"
                    className="las la-exclamation-triangle bx-tada alert-icon my-4 font-size-60"
                    onClick={() =>
                      setShowUnifyDeliveriesModal(!showUnifyDeliveriesModal)
                    }
                    role="button"
                    tabIndex="0"
                    aria-label="Open alert modal"
                  />
                </>
              )}
          </Col> */}
          </Row>
          {/*
          {saleValueAttentionAdvice && (
            <Row className="d-lg-none mx-0 mb-0">
              <SaleMessageBadge
                color="yellow"
                backgroundColor="rgba(236, 161, 32, 0.15)"
                style={{ marginBottom: '0.5rem' }}
              >
                Atenção! Este pedido tem um valor superior a R$ 1.500,00.
                Reforce sua atenção em confirmar a identidade do entregador que
                irá coletar o seu pedido. Qualquer dúvida, comunique o suporte
                logístico do Viddy.
              </SaleMessageBadge>
            </Row>
          )}
          {waitTillTomorrowAdvice && (
            <Row className="d-lg-none mx-0 mb-0">
              <SaleMessageBadge
                color="yellow"
                backgroundColor="rgba(236, 161, 32, 0.15)"
                style={{ marginBottom: '0.5rem' }}
              >
                Atenção! Que tal esperar para amanhã? Entregas muito tarde
                costumam demorar além do esperado.
              </SaleMessageBadge>
            </Row>
          )}
          {waitTillCommercialAdvice && (
            <Row className="d-lg-none mx-0 mb-0">
              <SaleMessageBadge
                color="yellow"
                backgroundColor="rgba(236, 161, 32, 0.15)"
                style={{ marginBottom: '0.5rem' }}
              >
                Atenção! Que tal aguardar às 9 horas? No horário comercial, as
                entregas costumam ser mais rápidas e com menos imprevistos.
              </SaleMessageBadge>
            </Row>
          )}
        */}

          {loading || shouldReloadCart ? (
            <Loading />
          ) : (
            <>
              {sale?.status_text !== 'Cancelado' && (
                <ConsultantManagementButtons
                  setShouldReloadCart={setShouldReloadCart}
                  salePaymentReverse={salePaymentReverse}
                  sale={sale}
                  delivery={delivery}
                  formRef={cuponsformRef}
                  separated
                  toggleFinishCancelledOrder={toggleFinishCancelledOrder}
                  setShouldShowColectDeliveryForm={
                    setShouldShowColectDeliveryForm
                  }
                  setShouldShowPaymentSuitcaseform={
                    setShouldShowPaymentSuitcaseform
                  }
                />
              )}
              {(saleValueAttentionAdvice ||
                waitTillTomorrowAdvice ||
                waitTillCommercialAdvice) && (
                <Col
                  xs={12}
                  sm={8}
                  className="mr-0 pl-0 pr-2"
                  style={{ marginTop: '-1rem' }}
                >
                  {saleValueAttentionAdvice && (
                    <SaleMessageBadge
                      color="yellow"
                      backgroundColor="rgba(236, 161, 32, 0.15)"
                      style={{ marginBottom: '0.5rem' }}
                    >
                      Atenção! Este pedido tem um valor superior a R$ 1.500,00.
                      Reforce sua atenção em confirmar a identidade do
                      entregador que irá coletar o seu pedido. Qualquer dúvida,
                      comunique o suporte logístico do Viddy.
                    </SaleMessageBadge>
                  )}
                  {waitTillTomorrowAdvice && (
                    <SaleMessageBadge
                      color="yellow"
                      backgroundColor="rgba(236, 161, 32, 0.15)"
                      style={{ marginBottom: '0.5rem' }}
                    >
                      Atenção! Que tal esperar para amanhã? Entregas muito tarde
                      costumam demorar além do esperado.
                    </SaleMessageBadge>
                  )}
                  {waitTillCommercialAdvice && (
                    <SaleMessageBadge
                      color="yellow"
                      backgroundColor="rgba(236, 161, 32, 0.15)"
                      style={{ marginBottom: '0.5rem' }}
                    >
                      Atenção! Que tal aguardar às 9 horas? No horário
                      comercial, as entregas costumam ser mais rápidas e com
                      menos imprevistos.
                    </SaleMessageBadge>
                  )}
                </Col>
              )}
              {sale.is_cross &&
                ['cross-manager', 'administrator', 'store-manager'].includes(
                  role
                ) &&
                !sale.cross_accepted &&
                sale.status_text === 'Aguardando aceite' && (
                  <>
                    <Row className="w-100 mx-0 d-none d-lg-flex">
                      <Col className="pr-2 pl-0" lg={8}>
                        <Card className="new-layout card">
                          <CardBody className="new-layout card-body px-3 py-3">
                            <Row className="w-100 mx-0 justify-content-between d-flex align-items-center">
                              <Col className="mr-0 pl-0 pr-0">
                                <span className="font-size-14 font-weight-600">
                                  Clique no botão ao lado para aceitar esse
                                  pedido
                                </span>
                              </Col>
                              <Col className="mr-0 pl-0 pr-0" align="end">
                                <Button
                                  className="new-layout-btn main mx-0 font-size-14 button-sale-action"
                                  color="success"
                                  size="lg"
                                  onClick={handleAcceptOrder}
                                >
                                  <i className="fa fa-check-circle pl-1 mr-2 font-size-14" />
                                  Aceitar pedido
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="w-100 mx-0 d-lg-none">
                      <Col className="pr-2 pl-0">
                        <Card className="new-layout card">
                          <CardBody className="new-layout card-body px-3 py-3">
                            <Row className="w-100 mx-0 justify-content-between d-flex align-items-center mb-2">
                              <Col className="mr-0 pl-0 pr-0">
                                <span className="font-size-16 font-weight-600">
                                  Deseja aceitar esse pedido?
                                </span>
                              </Col>
                            </Row>
                            <Row className="w-100 mx-0 justify-content-between d-flex align-items-center">
                              <Col className="mr-0 pl-0 pr-0">
                                <Button
                                  className="new-layout-btn main mx-0 font-size-14 button-sale-action"
                                  color="success"
                                  size="lg"
                                  onClick={handleAcceptOrder}
                                >
                                  <i className="fa fa-check-circle pl-1 mr-2 font-size-14" />
                                  Aceitar pedido
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </>
                )}
              <Row className="w-100 mx-0">
                <Col lg={showSidebar ? 8 : 7} className="px-0 pr-lg-2">
                  {((sale?.status >= 3 && sale?.status < 9) ||
                    sale?.status === 0) && (
                    <>
                      {(![
                        'Aguardando envio',
                        'Malinha entregue',
                        'Coleta realizada',
                        'Entregue',
                        'Cancelado',
                        'Aberto',
                      ].includes(sale.status_text) ||
                        (sale.status_text === 'Malinha entregue' &&
                          sale.return_delivery) ||
                        (sale.status_text === 'Entregue' &&
                          sale.return_delivery)) &&
                        !(
                          sale?.status_text === 'Aguardando envio' ||
                          (sale?.status_text === 'Malinha entregue' &&
                            !sale?.return_delivery) ||
                          sale?.status_text === 'Coleta realizada' ||
                          sale?.status === 9 ||
                          sale?.status === 0
                        ) &&
                        sale?.is_for_delivery && (
                          <Card className="new-layout card">
                            <CardBody className="new-layout card-body p-3">
                              <Row
                                className="font-size-16 font-weight-600 text-gray-700 justify-content-between mx-0 px-0 mb-0"
                                click={() => {
                                  setStatusDataCardExpand(
                                    !statusDataCardExpand
                                  );
                                }}
                              >
                                <div className="px-0 mb-0">
                                  {sale?.is_suitcase_delivery &&
                                    sale?.return_delivery &&
                                    'Coleta'}
                                  {!sale?.is_suitcase_delivery &&
                                    sale?.return_delivery &&
                                    'Devolução'}
                                  {!sale?.return_delivery && 'Entrega'}
                                </div>
                                <div className="px-0 mb-0">
                                  <IconButton
                                    icon={`las la-${
                                      statusDataCardExpand
                                        ? 'angle-up'
                                        : 'angle-right'
                                    }`}
                                    click={() => {
                                      setStatusDataCardExpand(
                                        !statusDataCardExpand
                                      );
                                    }}
                                    color="gray-700"
                                  />
                                </div>
                              </Row>
                              <Collapse isOpen={statusDataCardExpand}>
                                <Row className="mx-0 mb-0 mt-2">
                                  {sale?.delivery && (
                                    <Col className="mx-0 pl-0" xs={6} lg={3}>
                                      <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                        Loja
                                      </p>
                                      {sale?.store?.fantasy_name}
                                    </Col>
                                  )}
                                  <Col className="mx-0 pl-0" lg={3}>
                                    <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                      Endereço de coleta
                                    </p>
                                    {['Malinha entregue', 'Entregue'].includes(
                                      sale?.status_text
                                    ) &&
                                    sale?.delivery?.status === DELIVERED ? (
                                      <>
                                        {
                                          sale?.return_delivery_address
                                            ?.street_name
                                        }
                                        ,{' '}
                                        {sale?.return_delivery_address?.number}{' '}
                                        -{' '}
                                        {
                                          sale?.return_delivery_address
                                            ?.neighborhood
                                        }
                                        , {sale?.return_delivery_address?.city}{' '}
                                        - {sale?.return_delivery_address?.state}
                                        , CEP{' '}
                                        {sale?.return_delivery_address?.cep}
                                      </>
                                    ) : (
                                      <>
                                        {sale?.store?.address?.street_name},{' '}
                                        {sale?.store?.address?.number} -{' '}
                                        {sale?.store?.address?.neighborhood},{' '}
                                        {sale?.store?.address?.city} -{' '}
                                        {sale?.store?.address?.state}, CEP{' '}
                                        {sale?.store?.address?.cep}
                                      </>
                                    )}
                                  </Col>
                                  <Col className="mx-0 pl-0" lg={3}>
                                    <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                      Endereço de entrega
                                    </p>
                                    {['Malinha entregue', 'Entregue'].includes(
                                      sale?.status_text
                                    ) &&
                                    sale?.delivery?.status === DELIVERED ? (
                                      <>
                                        {
                                          sale?.return_store?.address
                                            ?.street_name
                                        }
                                        , {sale?.return_store?.address?.number}{' '}
                                        -{' '}
                                        {
                                          sale?.return_store?.address
                                            ?.neighborhood
                                        }
                                        , {sale?.return_store?.address?.city} -{' '}
                                        {sale?.return_store?.address?.state},
                                        CEP {sale?.return_store?.address?.cep}
                                      </>
                                    ) : (
                                      <>
                                        {sale?.delivery_address?.street_name},{' '}
                                        {sale?.delivery_address?.number} -{' '}
                                        {sale?.delivery_address?.neighborhood},{' '}
                                        {sale?.delivery_address?.city} -{' '}
                                        {sale?.delivery_address?.state}, CEP{' '}
                                        {sale?.delivery_address?.cep}
                                      </>
                                    )}
                                  </Col>
                                  {sale?.delivery && (
                                    <Col className="mx-0 pl-0" xs={6} lg={3}>
                                      <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                        Situação da entrega
                                      </p>
                                      <span
                                        className={`font-weight-600 ${defineDeliveryStatusColor(
                                          [
                                            'Malinha entregue',
                                            'Entregue',
                                          ].includes(sale?.status_text) &&
                                            sale?.return_delivery
                                            ? sale?.return_delivery?.status_text
                                            : sale?.delivery?.status_text
                                        )}`}
                                      >
                                        {[
                                          'Malinha entregue',
                                          'Entregue',
                                        ].includes(sale?.status_text) &&
                                        sale?.return_delivery
                                          ? sale?.return_delivery?.status_text
                                          : sale?.delivery?.status_text}
                                      </span>
                                    </Col>
                                  )}
                                  <Col className="mx-0 pl-0" xs={6} lg={3}>
                                    <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                      Transportadora
                                    </p>
                                    {sale?.return_delivery_method
                                      ? capitalize(
                                          sale?.return_delivery_method !==
                                            'PROPRIA'
                                            ? sale?.return_delivery_method
                                            : 'Alternativa'
                                        )
                                      : capitalize(
                                          sale?.delivery_method !== 'PROPRIA'
                                            ? sale?.delivery_method
                                            : 'Alternativa'
                                        )}
                                  </Col>
                                  <Col className="mx-0 pl-0" xs={6} lg={3}>
                                    <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                      Veículo
                                    </p>
                                    {['Malinha entregue', 'Entregue'].includes(
                                      sale?.status_text
                                    ) && sale?.return_delivery ? (
                                      <>
                                        {sale?.return_delivery_vehicle === 'M'
                                          ? 'Moto'
                                          : 'Carro'}
                                      </>
                                    ) : (
                                      <>
                                        {sale?.delivery_vehicle === 'M'
                                          ? 'Moto'
                                          : 'Carro'}
                                      </>
                                    )}
                                  </Col>
                                  {(sale?.status >= 7 || sale?.status === 4) &&
                                  sale?.return_delivery?.status !== SEARCHING &&
                                  sale?.return_delivery?.worker_name ? (
                                    <>
                                      <Col className="mx-0 pl-0" xs={6} lg={3}>
                                        <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                          Nome do entregador
                                        </p>
                                        {capitalize(
                                          sale?.return_delivery?.worker_name
                                        )}
                                      </Col>
                                      <Col className="mx-0 pl-0" xs={6} lg={3}>
                                        <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                          Tel do entregador
                                        </p>
                                        {formatPhone(
                                          sale?.return_delivery?.worker_phone
                                        )}
                                      </Col>
                                    </>
                                  ) : null}
                                  {sale?.status < 7 &&
                                  sale?.status !== 4 &&
                                  sale?.delivery?.status !== SEARCHING &&
                                  sale?.delivery?.worker_name ? (
                                    <>
                                      <Col className="mx-0 pl-0" xs={6} lg={3}>
                                        <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                          Nome do entregador
                                        </p>
                                        {capitalize(
                                          sale?.delivery?.worker_name
                                        )}
                                      </Col>
                                      <Col className="mx-0 pl-0" xs={6} lg={3}>
                                        <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                          Tel do entregador
                                        </p>
                                        {formatPhone(
                                          sale?.delivery?.worker_phone
                                        )}
                                      </Col>
                                    </>
                                  ) : null}
                                  {sale?.delivery_method === 'UBER' ||
                                  sale?.return_delivery_method === 'UBER' ? (
                                    <Col className="mx-0 pl-0" xs={6} lg={3}>
                                      <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                        Uber ID
                                      </p>
                                      {(sale?.return_delivery
                                        ? sale?.return_delivery?.uber_uuid ||
                                          '-'
                                        : sale?.delivery?.uber_uuid) || '-'}
                                    </Col>
                                  ) : (
                                    <Col className="mx-0 pl-0" xs={6} lg={3}>
                                      <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                        ID da entrega
                                      </p>
                                      {(sale?.return_delivery
                                        ? sale?.return_delivery
                                            ?.id_on_service || '-'
                                        : sale?.delivery?.id_on_service) || '-'}
                                    </Col>
                                  )}
                                  {sale?.return_delivery &&
                                    sale?.return_delivery?.status !==
                                      SEARCHING &&
                                    sale?.return_delivery?.pickup_code && (
                                      <Col xs={6} lg={3} className="mx-0 pl-0">
                                        <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                          Código de coleta
                                        </p>
                                        {sale?.return_delivery?.pickup_code}
                                      </Col>
                                    )}
                                  {sale?.return_delivery &&
                                    sale?.return_delivery?.status !==
                                      SEARCHING &&
                                    sale?.return_delivery?.return_code && (
                                      <Col xs={6} lg={3} className="mx-0 pl-0">
                                        <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                          Código de retorno
                                        </p>
                                        {sale?.return_delivery?.return_code}
                                      </Col>
                                    )}
                                  {!sale?.return_delivery &&
                                    sale?.delivery?.status !== SEARCHING &&
                                    sale?.delivery?.pickup_code && (
                                      <Col xs={6} lg={3} className="mx-0 pl-0">
                                        <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                          Código de coleta
                                        </p>
                                        {sale?.delivery?.pickup_code}
                                      </Col>
                                    )}
                                  {!sale?.return_delivery &&
                                    sale?.delivery?.status !== SEARCHING &&
                                    sale?.delivery?.return_code && (
                                      <Col xs={6} lg={3} className="mx-0 pl-0">
                                        <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                          Código de retorno
                                        </p>
                                        {sale?.delivery?.return_code}
                                      </Col>
                                    )}
                                </Row>

                                {!sale?.return_delivery &&
                                  sale?.delivery &&
                                  sale.delivery?.cancellation_reason && (
                                    <Col
                                      xs={6}
                                      className="px-0 mb-2 mb-lg-0 mt-2"
                                    >
                                      <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                                        Motivo do Cancelamento
                                      </div>
                                      <div className="font-size-14 font-weight-400 d-block">
                                        {sale.delivery?.cancellation_reason}
                                      </div>
                                    </Col>
                                  )}
                                {sale?.return_delivery &&
                                  sale.return_delivery?.cancellation_reason && (
                                    <Col
                                      xs={6}
                                      className="px-0 mb-2 mb-lg-0 mt-2"
                                    >
                                      <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                                        Motivo do Cancelamento
                                      </div>
                                      <div className="font-size-14 font-weight-400 d-block">
                                        {
                                          sale.return_delivery
                                            ?.cancellation_reason
                                        }
                                      </div>
                                    </Col>
                                  )}
                                {!sale?.is_suitcase_delivery &&
                                  !sale?.return_delivery &&
                                  sale?.delivery_score?.score && (
                                    <Col
                                      xs={6}
                                      lg={3}
                                      className="px-0 mb-2 mb-lg-0 mt-3"
                                    >
                                      <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                                        Avaliação de entrega
                                      </div>
                                      <div className="font-size-14 font-weight-400 d-block mt-1">
                                        {Array(sale?.delivery_score?.score)
                                          .fill(1)
                                          .map((_, i) => (
                                            <FaStar
                                              // eslint-disable-next-line react/no-array-index-key
                                              key={`${sale?.delivery_score?.user_name}${i}`}
                                              color="#ffd600"
                                              size={30}
                                            />
                                          ))}
                                        {Array(5 - sale?.delivery_score?.score)
                                          .fill(1)
                                          .map((_, i) => (
                                            <FaRegStar
                                              // eslint-disable-next-line react/no-array-index-key
                                              key={`${sale?.delivery_score?.user_name}${i}`}
                                              color="#2544b1"
                                              size={30}
                                            />
                                          ))}
                                      </div>
                                    </Col>
                                  )}
                                {!sale?.is_suitcase_delivery &&
                                  sale?.return_delivery_score?.score && (
                                    <Col
                                      xs={6}
                                      lg={3}
                                      className="px-0 mb-2 mb-lg-0 mt-3"
                                    >
                                      <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                                        Avaliação de entrega
                                      </div>
                                      <div className="font-size-14 font-weight-400 d-block mt-1">
                                        {Array(
                                          sale?.return_delivery_score?.score
                                        )
                                          .fill(1)
                                          .map((_, i) => (
                                            <FaStar
                                              // eslint-disable-next-line react/no-array-index-key
                                              key={`${sale?.return_delivery_score?.user_name}${i}`}
                                              color="#ffd600"
                                              size={30}
                                            />
                                          ))}
                                        {Array(
                                          5 - sale?.return_delivery_score?.score
                                        )
                                          .fill(1)
                                          .map((_, i) => (
                                            <FaRegStar
                                              // eslint-disable-next-line react/no-array-index-key
                                              key={`${sale?.return_delivery_score?.user_name}${i}`}
                                              color="#2544b1"
                                              size={30}
                                            />
                                          ))}
                                      </div>
                                    </Col>
                                  )}
                                {((!sale?.return_delivery &&
                                  sale?.delivery?.status !== DELIVERED) ||
                                  (sale?.return_delivery &&
                                    sale?.return_delivery?.status !==
                                      DELIVERED)) && (
                                  <Row className="mx-0 mb-0">
                                    <Col className="mx-0 px-0">
                                      <p className="font-size-14 font-weight-600 text-gray-600 mx-0 mt-2 mb-0">
                                        Link de rastreio
                                      </p>
                                      {!sale.return_delivery && (
                                        <>
                                          {sale?.delivery?.track_data ? (
                                            <a
                                              href={sale?.delivery?.track_data}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="text-black"
                                              id="deliveryTrackUrl"
                                            >
                                              {sale?.delivery?.track_data}
                                            </a>
                                          ) : (
                                            'Ainda não foi gerado um link de rastreio'
                                          )}
                                        </>
                                      )}
                                      {sale.return_delivery && (
                                        <>
                                          {sale?.return_delivery?.track_data ? (
                                            <a
                                              href={
                                                sale?.return_delivery
                                                  ?.track_data
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="text-black"
                                              id="deliveryTrackUrl"
                                            >
                                              {
                                                sale?.return_delivery
                                                  ?.track_data
                                              }
                                            </a>
                                          ) : (
                                            'Ainda não foi gerado um link de rastreio'
                                          )}
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                )}
                                {(sale?.status === 4 || sale?.status >= 7) &&
                                  sale?.return_delivery?.track_data && (
                                    <Row className="mx-0 mt-3 mb-0">
                                      <div className="mx-0 px-0">
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={
                                            sale?.return_delivery?.worker_name
                                              ? `
                                https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                  sale?.client?.telephone
                                )}&text=O entregador ${capitalize(
                                                  sale?.return_delivery
                                                    ?.worker_name
                                                )} aceitou sua corrida. Segue o link para rastreio ${
                                                  sale?.return_delivery
                                                    ?.track_data
                                                }
                              `
                                              : `
                                https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                  sale?.client?.telephone
                                )}&text=Acabei de chamar o entregador para coletar o seu pedido, vamos aguardar alguém aceitar a corrida. Assim que tiver informações do entregador, compartilho aqui!
                              `
                                          }
                                        >
                                          <Button
                                            id="shareTrackURL"
                                            color="info"
                                            className="new-layout-btn main d-flex align-items-center bg-light-blue border-0 text-primary"
                                            outline
                                          >
                                            <i className="las la-share mr-2" />
                                            <span className="font-size-14">
                                              Compartilhar
                                            </span>
                                          </Button>
                                        </a>
                                      </div>
                                      <div className="mx-0 px-0 ml-auto ml-lg-4">
                                        <Button
                                          id="copyTrackURL"
                                          color="info"
                                          onClick={() => {
                                            const dummy =
                                              document.createElement(
                                                'textarea'
                                              );
                                            document.body.appendChild(dummy);
                                            dummy.value =
                                              sale?.delivery?.track_data;
                                            dummy.select();
                                            document.execCommand('copy');
                                            document.body.removeChild(dummy);

                                            toast.success('Link copiado!');
                                          }}
                                          className="new-layout-btn main d-flex align-items-center bg-light-blue border-0 text-primary"
                                          outline
                                        >
                                          <i className="las la-copy mr-2" />
                                          <span className="font-size-14">
                                            Copiar Link
                                          </span>
                                        </Button>
                                      </div>
                                    </Row>
                                  )}
                                {sale?.status < 7 &&
                                  sale?.delivery?.status !== DELIVERED &&
                                  sale?.delivery?.track_data &&
                                  !sale?.return_delivery && (
                                    <Row className="mx-0 mt-3 mb-0">
                                      <div className="mx-0 px-0">
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={
                                            sale?.delivery?.worker_name
                                              ? `
                                https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                  sale?.client?.telephone
                                )}&text=O entregador ${capitalize(
                                                  sale?.delivery?.worker_name
                                                )} aceitou sua corrida. Segue o link para rastreio ${
                                                  sale?.delivery?.track_data
                                                }
                              `
                                              : `
                                https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                  sale?.client?.telephone
                                )}&text=Acabei de chamar o entregador para coletar o seu pedido, vamos aguardar alguém aceitar a corrida. Assim que tiver informações do entregador, compartilho aqui!
                              `
                                          }
                                        >
                                          <Button
                                            id="shareTrackURL"
                                            color="info"
                                            className="new-layout-btn main d-flex align-items-center bg-light-blue border-0 text-primary"
                                            outline
                                          >
                                            <i className="las la-share mr-2" />
                                            <span className="font-size-14">
                                              Compartilhar
                                            </span>
                                          </Button>
                                        </a>
                                      </div>
                                      <div className="mx-0 px-0 ml-4">
                                        <Button
                                          id="copyTrackURL"
                                          color="info"
                                          onClick={() => {
                                            const dummy =
                                              document.createElement(
                                                'textarea'
                                              );
                                            document.body.appendChild(dummy);
                                            dummy.value =
                                              sale?.delivery?.track_data;
                                            dummy.select();
                                            document.execCommand('copy');
                                            document.body.removeChild(dummy);

                                            toast.success('Link copiado!');
                                          }}
                                          className="new-layout-btn main d-flex align-items-center bg-light-blue border-0 text-primary"
                                          outline
                                        >
                                          <i className="las la-copy mr-2" />
                                          <span className="font-size-14">
                                            Copiar
                                          </span>
                                        </Button>
                                      </div>
                                    </Row>
                                  )}

                                {sale?.delivery?.qr_code && (
                                  <Row className="mx-0 mb-0">
                                    <Col
                                      xs={12}
                                      lg={12}
                                      className="pl-0 mt-3 ml-lg-1 pr-lg-2"
                                    >
                                      <DeliveryQrcode
                                        qrCode={sale?.delivery?.qr_code}
                                        sale={sale}
                                      />
                                    </Col>
                                  </Row>
                                )}

                                {sale?.return_delivery &&
                                  sale?.return_delivery?.status === SEARCHING &&
                                  sale?.return_delivery?.status ===
                                    GOING_TO_STORE &&
                                  sale?.return_delivery?.status ===
                                    ARRIVED_AT_STORE && (
                                    <hr className="d-flex ml-0 ml-lg-2 mr-lg-4 mb-2" />
                                  )}

                                <Row className="mx-0 mt-4 mb-0 justify-content-end">
                                  {/* ((!sale?.return_delivery &&
                                      sale?.delivery?.status !== SEARCHING &&
                                      sale?.delivery?.status !== DELIVERED) ||
                                      (sale?.return_delivery &&
                                        sale?.return_delivery?.status !==
                                          SEARCHING &&
                                        sale?.return_delivery?.status !==
                                          DELIVERED)) && (
                                      <Col
                                        xs={12}
                                        sm={4}
                                        className="px-0 pr-sm-2"
                                      >
                                        <Button
                                          color="info"
                                          className="new-layout-btn main mb-2 mr-lg-2 w-100"
                                          onClick={handleResetDelivery}
                                          outline
                                        >
                                          Reiniciar entrega
                                        </Button>
                                      </Col>
                                    ) */}
                                  {((!sale?.return_delivery &&
                                    (sale?.delivery?.status ===
                                      GOING_TO_STORE ||
                                      sale?.delivery?.status ===
                                        ARRIVED_AT_STORE ||
                                      sale?.delivery?.status === CANCELLED)) ||
                                    (sale?.return_delivery &&
                                      (sale?.return_delivery?.status ===
                                        GOING_TO_CLIENT ||
                                        sale?.return_delivery?.status ===
                                          ARRIVED_AT_CLIENT ||
                                        sale?.return_delivery?.status ===
                                          CANCELLED))) &&
                                    !hideButtonSaleCross && (
                                      <Col
                                        xs={12}
                                        sm={5}
                                        className="px-0 px-lg-2"
                                      >
                                        <Button
                                          color="info"
                                          className="relative new-layout-btn main mb-2 mr-lg-2 w-100"
                                          onClick={handleRetryDeliveryNow}
                                          disabled={
                                            shouldDisableDeliveryForceRetry
                                          }
                                          outline
                                        >
                                          <span
                                            style={{
                                              marginRight:
                                                shouldDisableDeliveryForceRetry
                                                  ? '1.5rem'
                                                  : undefined,
                                            }}
                                          >
                                            Nova tentativa de entrega{' '}
                                          </span>
                                          {shouldDisableDeliveryForceRetry && (
                                            <TimerDisplay
                                              timestamp={
                                                dateNow - lastDeliveryForceRetry
                                              }
                                            />
                                          )}
                                        </Button>
                                      </Col>
                                    )}
                                  {((!sale?.return_delivery &&
                                    (sale?.delivery?.status === SEARCHING ||
                                      sale?.delivery?.status ===
                                        GOING_TO_STORE ||
                                      sale?.delivery?.status ===
                                        ARRIVED_AT_STORE)) ||
                                    (sale?.return_delivery &&
                                      (sale?.return_delivery?.status ===
                                        SEARCHING ||
                                        sale?.return_delivery?.status ===
                                          GOING_TO_CLIENT ||
                                        sale?.return_delivery?.status ===
                                          ARRIVED_AT_CLIENT))) &&
                                    !hideButtonSaleCross && (
                                      <Col
                                        xs={12}
                                        sm={4}
                                        className="pl-sm-2 px-0"
                                      >
                                        <Button
                                          color="danger"
                                          className="new-layout-btn main mb-2 mr-lg-2 w-100"
                                          onClick={toggleCancelDelivery}
                                          outline
                                        >
                                          Cancelar Entrega
                                        </Button>
                                      </Col>
                                    )}
                                </Row>
                              </Collapse>
                            </CardBody>
                          </Card>
                        )}
                    </>
                  )}
                  {sale?.status_text ===
                    'Pagamento rejeitado pelo adquirente' ||
                  sale?.status_text === 'Em pagamento' ||
                  (sale?.status_text === 'Coleta realizada' &&
                    sale?.amount !== '0.00') ||
                  sale?.status_text ===
                    'Pagamento rejeitado automaticamente pelo serviço' ? (
                    <PaymentCard cart={sale} />
                  ) : null}
                  <div className="d-lg-none">
                    {subscriberId !== subscriberViddyComercial && (
                      <>
                        <PaymentInfoCard cart={sale} />
                        <BillingInfoCard cart={sale} />
                      </>
                    )}
                    <ColectInfoCard cart={sale} />
                    <DeliveryInfoCard cart={sale} />
                  </div>
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      <CartMainInfoCard
                        cart={sale}
                        setData={setData}
                        setProductsData={setProductsData}
                        shareableURL={shareableURL}
                      />
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      <CustomerDataCard cart={sale} customer={client} />
                    </Col>
                  </Row>
                  {subscriberId !== subscriberViddyComercial && (
                    <Row className="w-100 mx-0 d-none d-lg-flex">
                      <Col className="px-0">
                        {sale.products.length > 0 && (
                          <ProductsListCard products={sale.products} />
                        )}
                        {/* <TaxCuponsCard
                        cart={sale}
                        handleUploadTaxCoupon={handleUploadTaxCoupon}
                      /> */}
                        {!hideButtonSaleCross && (
                          <CuponsNumberCard
                            cart={sale}
                            formRef={cuponsformRef}
                            handle={handleAddCuponsNumber}
                          />
                        )}
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col lg={showSidebar ? 4 : 5} className="px-0 pl-lg-3">
                  <Row className="w-100 mx-0 d-none d-lg-flex">
                    <Col className="px-0">
                      <SaleStatusBadge
                        cart={sale}
                        cartDetails
                        extraClass="mb-0"
                      />
                    </Col>
                  </Row>
                  <FinalValueCard cart={sale} />
                  <div className="d-none d-lg-block">
                    {subscriberId !== subscriberViddyComercial && (
                      <>
                        <PaymentInfoCard cart={sale} />
                        <BillingInfoCard cart={sale} />
                      </>
                    )}
                    <ColectInfoCard cart={sale} />
                    <DeliveryInfoCard cart={sale} />
                  </div>
                  {subscriberId !== subscriberViddyComercial && (
                    <div className="px-0 d-lg-none">
                      {sale.products.length > 0 && (
                        <ProductsListCard products={products} />
                      )}
                      {/* <TaxCuponsCard
                      cart={sale}
                      handleUploadTaxCoupon={handleUploadTaxCoupon}
                    /> */}
                      {!hideButtonSaleCross && (
                        <CuponsNumberCard
                          cart={sale}
                          formRef={cuponsformRef}
                          handle={handleAddCuponsNumber}
                        />
                      )}
                    </div>
                  )}
                  {/* {showGiftBoxesCard && <GiftBoxesCard products={products} />} */}
                </Col>
              </Row>
              {showButtons && (
                <Col lg={9} className="px-0">
                  <CartManagementButtons
                    cart={sale}
                    setData={setData}
                    setProductsData={setProductsData}
                  />
                </Col>
              )}
            </>
          )}
        </Container>

        {/* {delivery && (
        <Modal
          isOpen={isRetryDeliveryModalOpen}
          toggle={toggleRetryDelivery}
          scrollable
        >
          <ModalHeader toggle={toggleRetryDelivery}>
            Nova tentativa de Entrega
          </ModalHeader>

          <ModalBody>
            <h5>
              Deseja realizar uma nova tentativa de entrega para o pedido #
              <strong>{sale?.order_number}</strong>?
            </h5>
            <h5>Nova tentativa agora?</h5>
            <CustomInput
              type="switch"
              id="deliveryNow"
              name="deliveryNow"
              label="Nova tentativa de entrega agora"
              checked={deliveryNow}
              className="text-primary z-index-0"
              onChange={() => setDeliveryNow(!deliveryNow)}
            />

            {!deliveryNow && (
              <Row className="w-100 mx-0 mt-2">
                Para nova tentativa, tempo mínimo de 35 minutos a partir de
                agora.
                <Col lg={6} className="px-0 pr-lg-2 mb-2 mb-lg-0">
                  <DatePicker
                    value={scheduledDeliveryDate}
                    onChange={selectedDate =>
                      setScheduledDeliveryDate(selectedDate)
                    }
                    mask="__/__/___"
                    placeholder="Dia"
                    disablePast
                  />
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <TimePicker
                    value={scheduledDeliveryTime}
                    onChange={selectedTime =>
                      setScheduledDeliveryTime(selectedTime)
                    }
                    mask="__:__ _M"
                    placeholder="Horário"
                  />
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() =>
                deliveryNow ? handleRetryDeliveryNow() : handleRetryDelivery()
              }
            >
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      )} */}

        {delivery && (
          <Modal
            isOpen={isCancelDeliveryModalOpen}
            toggle={toggleCancelDelivery}
            scrollable
          >
            <ModalHeader toggle={toggleCancelDelivery}>
              Cancelar Entrega
            </ModalHeader>

            <Form onSubmit={handleCancelDelivery} ref={formRef}>
              <ModalBody>
                <h5>
                  A entrega do pedido #<strong>{sale?.order_number}</strong>{' '}
                  será cancelada.
                </h5>
                <FormGroup className="mt-3 mb-0">
                  <Input
                    name="reason"
                    placeholder="Motivo do cancelamento"
                    type="text"
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="new-layout-btn main"
                  type="submit"
                  color="danger"
                >
                  Confirmar
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        )}

        {delivery && (
          <Modal
            isOpen={isFinishCancelledOrderModalOpen}
            toggle={toggleFinishCancelledOrder}
          >
            <ModalHeader toggle={toggleFinishCancelledOrder}>
              Marcar o pedido como entregue
            </ModalHeader>

            <Form onSubmit={handleFinishCancelledOrder} ref={formRef}>
              <ModalBody>
                <h6>
                  <strong>Número do pedido: {sale?.order_number}.</strong>
                </h6>
                <h5>
                  O pedido de <strong>{sale?.client.name}</strong> será marcado
                  como finalizado mesmo tendo sua entrega cancelada.
                </h5>

                <FormGroup className="mt-3 mb-0">
                  <InputSelect
                    options={[
                      {
                        label: 'Entrega realizada por outro parceiro logístico',
                        value: 'Entrega realizada por outro parceiro logístico',
                      },
                      {
                        label: 'O pedido não foi entregue',
                        value: 'O pedido não foi entregue',
                      },
                      {
                        label: 'O cliente decidiu retirar em loja',
                        value: 'O cliente decidiu retirar em loja',
                      },
                      {
                        label: 'Outros',
                        value: 'Outros',
                      },
                    ]}
                    name="finalization_reason"
                    onChange={setFinalizationReason}
                    isSearchable
                    placeholder="Motivo"
                    type="text"
                    value={finalizationReason}
                    styles={{
                      control: base => ({ ...base, height: '39px' }),
                    }}
                  />
                </FormGroup>
                <FormGroup className="mt-3 mb-0">
                  <Input
                    name="finalization_details"
                    placeholder="Descrição detalhada"
                    type="text"
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={() => setIsFinishCancelledOrderModalOpen(false)}
                  color="secondary"
                >
                  Sair
                </Button>
                <Button type="submit" color="success">
                  Confirmar
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        )}

        <RatingDeliveryModal
          setShouldReloadCart={setShouldReloadCart}
          showModal={showRatingModal}
          toggle={toggleRating}
          cart={sale}
        />

        <UnifyDeliveriesModal
          showModal={showUnifyDeliveriesModal}
          toggle={toggle}
          alerts={alerts}
          alertsLength={alertsLength}
          unifiableCarts={unifiableCarts}
          cartsToUnify={cartsToUnify}
          addCart={handleAddToUnifiedCarts}
          removeCart={handleRemoveFromUnifiedCarts}
          unifyDeliveries={handleUnifyDeliveries}
        />
      </div>
    </>
  );
};

export default Sale;
